import DescriptionEdit from './views/DescriptionEdit'
import DescriptionCreate from './views/DescriptionCreate'
import DescriptionList from './views/DescriptionList'

export default {
  name:"descriptions",
  edit:DescriptionEdit,
  create:DescriptionCreate,
  list:DescriptionList,
}
