import React, { useState, useEffect } from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import { useMutation } from '@apollo/client';

import { updateScoreMutation } from '../../../utils/graph/badges';

import DocumentsList from './DocumentsList';
import './styles.css';

const Badge = ({ badgeId, badgeTitle, pic, score, rangeId, degreeRefId, degreeRefTitle, docs, links, tags, refetchBadges, selectDocs }) => {
    const [visible, setVisible] = useState(false);
    const [rangeInput, setRangeInput] = useState(score);
    useEffect(() => {
        setRangeInput(score);
    }, [score]);

    const [updateRange] = useMutation(updateScoreMutation, {
        variables: {rangeId: rangeId,
                    rangeInput: rangeInput},
    });

    const onChangeRange = (e) => {
        if (Number(e.target.value)) {
            setRangeInput(parseInt(e.target.value));
        }
        if (e.target.value === "") {
            setRangeInput(0);
        }
    }

    const onClickRange = () => {
        if (score !== rangeInput && rangeId !== 0) {
            updateRange();
        }
    }

    return (
        <Card>
            <CardContent className="badge">
                <h2>{badgeTitle}</h2>
                <div><img className="badge_img" src={pic} alt={badgeTitle} /></div>
                <div className="badge_range">
                    <input
                        className="badge_range_input"
                        type="text"
                        value={rangeInput}
                        onChange={(e) => onChangeRange(e)}
                    />
                    <Button
                        classes={{label: "Mui_button"}}
                        onClick={onClickRange}
                    >
                        OK
                    </Button>
                </div>
                <h3>{degreeRefId}</h3>
                <h3>{degreeRefTitle}</h3>
                <div>
                    <IconButton
                        onClick={() => setVisible(!visible)}
                    >
                        <h3 className="badge_doc">Documents</h3>
                    </IconButton>
                </div>
            </CardContent>
            {visible &&
                <DocumentsList
                    badgeId={badgeId}
                    docs={docs}
                    links={links}
                    tags={tags} 
                    refetchBadges={refetchBadges}
                    selectDocs={selectDocs}
                />
            }
        </Card>
    )
}

export default Badge;