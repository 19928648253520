import React from 'react';
import { SimpleForm, ReferenceInput, AutocompleteInput, TextInput, Create, NumberInput, SelectInput } from 'react-admin';
import { parse } from "query-string";

import { tagType, flagFilter } from '../../const/const';
import {validatePositiveNumber} from '../../../utils/validation'

const UserTagCreate = props => {
  const { utuserid: utuserid_string } = parse(props.location.search);
  const utuserid = utuserid_string ? parseInt(utuserid_string, 10) : "";
  return (
  <Create {...props}>
      <SimpleForm defaultValue={{utuserid}} redirect={`/users/${utuserid}/2`}>
        <NumberInput source="utuserid" label="utuserid" validate={validatePositiveNumber}/>
        <ReferenceInput source="utid" optionValue="_id" reference="tags" label="Tag" filterToQuery={searchText => ({ taname: searchText })}>
          <AutocompleteInput optionText="taname" />
        </ReferenceInput>
        <SelectInput source="uttype" choices={tagType} optionValue="_id" />
        <NumberInput source="utproba" label="proba" />
        <NumberInput source="utjoinedproba" label="joinedproba" />
        <NumberInput source="utxpdegree" label="xpdegree" />
        <TextInput source='utupdatesource' label='utupdatesource'/>
        <SelectInput initialValue={1} source="utflag" choices={flagFilter} optionValue="_id" label='flag'/>
        <NumberInput initialValue={0} source="utorder" label="order" />
        <NumberInput source="utalpha" label="alpha" />
      </SimpleForm>
  </Create>
)};

export default UserTagCreate;
