import React from 'react';
import { TextInput, Edit, SimpleForm, ReferenceInput, SelectInput, NumberInput, BooleanInput} from 'react-admin';
import Toolbar from '../../CustomLayout/Components/Toolbar';
import {flagFilter} from '../../const/const'
import {validatePositiveNumber, validateName} from '../../../utils/validation'

const CodescriptiontypeEdit = props => (
  <Edit {...props}>
      <SimpleForm toolbar={<Toolbar />}>
        <TextInput source="id" label="Id" disabled/>
        <ReferenceInput source="codtnotionid" reference="conotions" label="Notion name">
          <SelectInput optionText="noname" />
        </ReferenceInput>
        <NumberInput source="codtformatid" label="Format Id" validate={validatePositiveNumber}/>
        <TextInput source="codtname" label="Codtname" validate={validateName}/> 
        <TextInput source="codtfieldname" label="Codtfieldname" /> 
        <TextInput source="codtfieldparam" label="Codtfieldparam" />  
        <BooleanInput source="codtmultiple" label="Codtmultiple"/>
        <BooleanInput source="codtargscreate" label="Codtargscreate"/>
        <SelectInput source="codtflag" choices={flagFilter} optionValue="_id" label="flag"/>
        <TextInput source="codtdescription" label="Codtdescription" />  
        <BooleanInput source="codtdoaudit" label="Codtdoaudit"/>
      </SimpleForm>
  </Edit>
);

export default CodescriptiontypeEdit;