import React from 'react';
import { TextInput, Edit, SimpleForm, NumberInput, SelectInput } from 'react-admin';
import JsonInput from '../../../utils/JsonInput';
import Toolbar from '../../CustomLayout/Components/Toolbar';
import {jsonValidation} from '../../../utils/validation'

import { flagFilter } from '../../const/const';

const validateScvalueJson = [jsonValidation]

const JsonTextInput = (props) => {
  const {label, source, parse} = props;
  try {
    return (
      <JsonInput source={source} validate={validateScvalueJson} label={label} parse={parse}/>
      )
    } catch (error) {
    return (
      <TextInput source={source} label={label} />
    )
  }
}

const CotypeEdit = props => {

  return (
  <Edit {...props}>
    <SimpleForm toolbar={<Toolbar />}>
      <NumberInput source="id" label="Id" disabled/>
      <NumberInput source="sctype" label="Type id" disabled/>
      <SelectInput source="scflag" choices={flagFilter} optionValue="_id" />
      <JsonTextInput source="scvalue" label="scvalue"/>
      <JsonInput source="scvaluejson" validate={validateScvalueJson} label="scvaluejson"/>
    </SimpleForm>
  </Edit>
)};

export default CotypeEdit;

