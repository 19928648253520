import React from 'react';
import {  Edit, SimpleForm, TextInput, SelectInput } from 'react-admin';
import Toolbar from '../../CustomLayout/Components/Toolbar';
import { langueOption, flagFilter } from '../../const/const';

const TagEdit = props => (
  <Edit {...props}>
      <SimpleForm toolbar={<Toolbar />}>
          <SelectInput source="tllanguage" choices={langueOption} optionText="name" label="language" optionValue="_id"/>
          <TextInput source="tllabel" label="label"/>
          <SelectInput source="tlflag" choices={flagFilter} optionValue="_id" label='flag'/>
      </SimpleForm>
  </Edit>
);

export default TagEdit;
