import CoserverconfigtypeList from './views/CoserverconfigtypeList'
import CoserverconfigtypeEdit from './views/CoserverconfigtypeEdit'
import CoserverconfigtypeCreate from './views/CoserverconfigtypeCreate'

export default {
  name:"serverconfigtypes",
  list:CoserverconfigtypeList,
  edit:CoserverconfigtypeEdit,
  create:CoserverconfigtypeCreate,
}
