const tv4 = require('tv4');

const RefJsonServer88 = require('../json-schemas/validators/sctype88.json');
const RefJsonServer104 = require('../json-schemas/validators/sctype104.json');

const jsonValidation = (value) => {
  try{
    if (value)
      JSON.parse(value)
    return []
  } catch(e) {
    return e.message
  }
}

function validateJson(value, jsonSchema) {
  try {
    value = JSON.parse(value)
    tv4.validate(value, jsonSchema);
    if (tv4.error) return tv4.error;

    return []
  }
  catch (e) {
    return e.message
  }
}

const validateJson88 = (value, allValues) => {
  try{
    value = JSON.parse(value)
    tv4.validate(value, RefJsonServer88);
    if (tv4.error) return tv4.error;

    if (!allValues.cotname.includes(value.defaultType))
       return "defaultType must be in coreObjectTypes";
    return []
  } catch(e) {
    return e.message
  }
}

const validateJson104 = (value, allValues) => {
  try{
    value = JSON.parse(value)
    tv4.validate(value, RefJsonServer104);
    if (tv4.error) return tv4.error;

    value.workflows.forEach(workflow => {
      if (!allValues.couttname.includes(workflow.userType))
        throw new Error("Every userType of workflows must be in codusertypes");
    });
    return []
  } catch(e) {
    return e.message
  }
}

const validateNoname = (value, allValue) => {
  try {
    const regex = /^[A-Z]/
    if (!regex.test(value))
      return "Noname doit être en Camel Case avec prermière lettre en majuscule";
    if (value !== JSON.parse(allValue.server88).notion)
      return "Noname doit correspondre au champ notion du sctype88";
    if (value !== JSON.parse(allValue.server104).notion)
      return "Noname doit correspondre au champ notion du sctype104";
    return null
  } catch (error) {
    return "sctype88 and sctype104 must be valid"
  }
}

const validateName = (value, allValues) => {
  if (!value)
    return "name must not be empty"
  const regex = /^[a-z][a-zA-Z]*$/
  if (!regex.test(value)) 
    return "name must have only letters and must be camelCase";
  return null;
}

const validatePositiveNumber = (value, allValue) => {
  if (value && value < 0)
    return 'value must be positive or 0'
  return null
}

module.exports = {validateName, validatePositiveNumber, validateJson88, validateJson104, validateNoname, validateJson, jsonValidation}