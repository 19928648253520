import React from 'react';
import { TextInput, Create, SimpleForm, ReferenceInput, SelectInput, NumberInput, BooleanInput } from 'react-admin';
import {validatePositiveNumber, validateName} from '../../../utils/validation'
import {flagFilter} from '../../const/const'

import { parse } from "query-string";

const helperTextCodtname = "Pas d'espace, caractères spéciaux, chiffres, ne pas utiliser un nom réservé pour les attributs standards (user, tags, descriptions, events etc.)";

const CodusertypeEdit = props => {
  const { codtnotionid: codtnotionid_string } = parse(props.location.search);
  const codtnotionid = codtnotionid_string ? parseInt(codtnotionid_string, 10) : "";

  const defaultValue = {
    codtnotionid,
    codtformatid: 1,
    codtmultiple: false,
    codtargscreate: false,
    codtflag: 1,
    codtdoaudit: false,
  }
  return (
    <Create {...props}>
        <SimpleForm defaultValue={defaultValue} redirect={codtnotionid ? `/conotions/${codtnotionid}/2` : '/codescriptiontypes'}>
          <ReferenceInput source="codtnotionid" reference="conotions" label="Notion name">
            <SelectInput optionText="noname" />
          </ReferenceInput>
          <NumberInput source="codtformatid" label="Format Id" validate={validatePositiveNumber}/>
          <TextInput helperText={helperTextCodtname} source="codtname" label="Codtname" validate={validateName}/> 
          <TextInput source="codtfieldname" label="Codtfieldname" /> 
          <TextInput source="codtfieldparam" label="Codtfieldparam" />  
          <BooleanInput source="codtmultiple" label="Codtmultiple"/>
          <BooleanInput source="codtargscreate" label="Codtargscreate"/>
          <SelectInput source="codtflag" choices={flagFilter} optionValue="_id" label="flag"/>
          <TextInput source="codtdescription" label="Codtdescription" />  
          <BooleanInput source="codtdoaudit" label="Codtdoaudit"/>
        </SimpleForm>
    </Create>
  );
}

export default CodusertypeEdit;