import React from 'react';
import { NumberInput, Create, TextInput, SimpleForm } from 'react-admin';
import JsonInput from '../../../utils/JsonInput';
import {validateNoname, validateJson} from '../../../utils/validation'

const RefJsonServer88 = require('../../../json-schemas/validators/sctype88.json');
const RefJsonServer104 = require('../../../json-schemas/validators/sctype104.json');

const emptyServer88 = require('../../../json-schemas/empty-schemas/sctype88.json');
const emptyServer104 = require('../../../json-schemas/empty-schemas/sctype104.json');

const validateJson104 = (value) => validateJson(value, RefJsonServer104);
const validateJson88 = (value) => validateJson(value, RefJsonServer88);

const validateScvalueJson88 = [validateJson88]
const validateScvalueJson104 = [validateJson104]

const helperTextNocore = "Noname doit être en Camel Case avec prermière lettre en majuscule";

const defaultValue = {
  noflag: 3,
  nocore: 'oc',
}

const ConotionEdit = props => (
  <Create {...props}>
    <SimpleForm defaultValue={defaultValue}>
        <div style={{"fontStyle": "italic", color: "grey", width: "95%", padding: "2%", border: "1px solid #E7E3E3"}}>
          <p>Etapes pour créer un nouveau type d'object core :</p>
          <ol>
            <li>Créer une conotion avec un noname et le reporter dans les champs notion des deux json Sctype ci-dessous</li>
            <li>Enregistrer la conotion pour la créer</li>
            <li>Créer un description type dans l'onglet codescriptiontypes</li>
            <li>Créer un usertype dans l'onglet codusertypes</li>
            <li>Créer un cootype dans l'onglet coobjecttypes</li>
            <li>Reporter le / les usertype(s) dans le champs workflows / userType du Sctype104 ci-dessous</li>
            <li>Reporter le / les cootype(s) champs defaulType du Sctype88 ci-dessous</li>
            <li>Passer le flag de la conotion à 1</li>
          </ol>
        </div>
        <TextInput helperText={helperTextNocore} source="nocore" label="Cotype name" disabled/>
        <NumberInput source="noflag" label="Flag" disabled/>
        <TextInput source="noname" label="noname" validate={validateNoname}/>
        <JsonInput source="server88" validate={validateScvalueJson88} label="Sctype 88" initialValue={JSON.stringify(emptyServer88, null, 2)}/>
        <JsonInput source="server104" validate={validateScvalueJson104} label="Sctype 104" initialValue={JSON.stringify(emptyServer104, null, 2)}/>
    </SimpleForm>
  </Create>
);

export default ConotionEdit;
