import React from 'react';
import { Datagrid, TextField, Filter, NumberField, SelectInput, Pagination, SelectField, ReferenceField, NumberInput, ReferenceInput, AutocompleteInput, List } from 'react-admin';
import { paginationRow, auditTypes, flagFilter } from '../../const/const';
import CustomNumberFieldRedirectToDoc from '../../CustomLayout/Components/CustomNumberFieldRedirectToDoc';

const LearningBadgeDocumentFilters = (props) => (
  <Filter {...props}>
      <SelectInput source="lbdflag" choices={flagFilter} optionValue="_id" label="Search by flag"/>
      <NumberInput source="lbddoid" label="Search by docid"/>
      <NumberInput source="lbdlbid" label="Search by badgeid"/>
      <ReferenceInput source="lbdlbid" reference="learningbadges" label="Search by Badge" filterToQuery={searchText => ({ lbtitle: searchText })}>
        <AutocompleteInput optionText="lbtitle"/>
      </ReferenceInput>
      <SelectInput source="lbdaudittype" choices={auditTypes} optionValue="_id" label="Search by Audit Type"/>
      <NumberInput source="lbdaudittype" label="Search by Audit TypeId"/>
  </Filter>
);

const NewPagination = props => <Pagination rowsPerPageOptions={paginationRow} {...props} />;

const LearningBadgeDocumentsList = props => (
  <List {...props}  bulkActionButtons={false} pagination={<NewPagination />} filters={<LearningBadgeDocumentFilters/>}>
      <Datagrid rowClick="edit">
          <NumberField source="id" label="id" />
          <CustomNumberFieldRedirectToDoc source="lbddoid" label="Docid" />
          <ReferenceField source="lbdlbid" reference="learningbadges" label="Badge">
            <TextField source="lbtitle" label="name" />
          </ReferenceField>
          <NumberField source="lbdlbid" label="BadgeId" />
          <SelectField source="lbdaudittype" choices={auditTypes} optionValue="_id" label="Audit Type"/>
          <NumberField source="lbdscore" label="Score" />
          <NumberField source="lbdflag" label="Flag" />
      </Datagrid>
  </List>
);

export default LearningBadgeDocumentsList;
