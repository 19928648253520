import React from 'react';
import { List, Datagrid, TextField, Filter, NumberInput, BooleanInput, TextInput, NumberField, BooleanField, SelectInput, Pagination } from 'react-admin';
import { flagFilter, paginationRow } from '../../const/const'

const ClusterFilters = (props) => (
  <Filter {...props}>
      <NumberInput label="Search by id" source="id"  />
      <TextInput label="Search by name" source="clname"  />
      <BooleanInput label="Search by type" source="cltype"  />
      <NumberField label="Search by metatag" source="clmetatag"  />
      <NumberField label="Search by parentId" source="clparentid"  />
      <NumberField label="Search by deepness" source="cldeepness"  />
      <SelectInput choices={flagFilter} source="clflag" label="Search by flag" />
  </Filter>
);

const NewPagination = props => <Pagination rowsPerPageOptions={paginationRow} {...props} />;

const ClustersList = props => (
  <List filters={<ClusterFilters />} {...props}  bulkActionButtons={false} pagination={<NewPagination />}>
      <Datagrid rowClick="edit">
          <NumberField source="id" label="clusterId"/>
          <TextField source="clname" label="name" />
          <BooleanField source="cltype" label="type" />
          <NumberField source="clflag" label="flag" />
          <TextField source="clmetatag" label="metatag" />
          <NumberField source="clparentid" label="parentId" />
          <NumberField source="cldeepness" label="deepness" />
      </Datagrid>
  </List>
);




export default ClustersList;
