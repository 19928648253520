import React from 'react';
import {  Create, SimpleForm, NumberInput, ReferenceInput, AutocompleteInput, SelectInput } from 'react-admin';
import { parse } from "query-string";
import { flagFilter } from '../../const/const';

const LearningBadgeRangeCreate = props => {
  const { lbrlbid: lbrlbid_string } = parse(props.location.search);
  const lbrlbid = lbrlbid_string ? parseInt(lbrlbid_string, 10) : "";
  const defaultValue = {
    lbrlbid,
    lbrscore: 0,
    lbrflag: 1,
  }
  return (
    <Create {...props} >
      <SimpleForm defaultValue={defaultValue} redirect={lbrlbid ? `/learningbadges/${lbrlbid}/3` : '/learningbadgeranges'}>
        <ReferenceInput source="lbrlbid" reference="learningbadges" label="Badge" filterToQuery={searchText => ({ lbtitle: searchText })}>
          <AutocompleteInput optionText="lbtitle"/>
        </ReferenceInput>
        <NumberInput source="lbrscore" label="Score" />
        <SelectInput source="lbrflag" choices={flagFilter} optionValue="_id" label='flag'/>
      </SimpleForm>
    </Create>
  );
}

export default LearningBadgeRangeCreate;
