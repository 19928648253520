import React from 'react';
  import {
    TextField, Edit, ReferenceArrayField, ReferenceField, DeleteButton, Datagrid,
    TextInput, TabbedForm, FormTab, NumberField, Button, SelectInput
  } from 'react-admin';
import JsonInput from '../../../utils/JsonInput';
import Toolbar from '../../CustomLayout/Components/Toolbar';
import {validateNoname, validateJson88, validateJson104} from '../../../utils/validation'
import {flagFilter} from '../../const/const'


const helperTextNoname = "Camel Case avec prermière lettre en majuscule, doit correspondre au champ notion du sctype88 et du sctype104";
const helperTextSctype88 = "defaultType: type par défaut, parmi les types de la table coreobjecttype";
const helperTextSctype104 = "userType: type par défaut, parmi les types de la table cousertype";



const validateScvalueJson88 = [validateJson88]
const validateScvalueJson104 = [validateJson104]

const AddcodescriptiontypeButton = ({ record }) => (
  <Button
    label="Add a co description type"
    href={`#/codescriptiontypes/create?codtnotionid=${record.id}`}
  />
);
const AddcodusertypeButton = ({ record }) => {
  return (
  <Button
    label="Add a co user type"
    href={`#/codusertypes/create?coutnotionid=${record.id}`}
  />
)};

const AddcoobjecttypeButton = ({ record }) => {
  return (
  <Button
    label="Add a co type"
    href={`#/cotypes/create?cotnotionid=${record.id}`}
  />
)};


const ConotionEdit = props => {
  return (
  <Edit {...props}>
    <TabbedForm toolbar={<Toolbar />}>
      <FormTab label='conotion'>
        <div style={{"fontStyle": "italic", color: "grey", width: "95%", padding: "2%", border: "1px solid #E7E3E3"}}>
          <p>Etapes pour créer un nouveau type d'object core :</p>
          <ol>
            <li>Créer une conotion avec un noname et le reporter dans les champs notion des deux json Sctype ci-dessous</li>
            <li>Enregistrer la conotion pour la créer</li>
            <li>Créer un description type dans l'onglet codescriptiontypes</li>
            <li>Créer un usertype dans l'onglet codusertypes</li>
            <li>Créer un cootype dans l'onglet coobjecttypes</li>
            <li>Reporter le / les usertype(s) dans le champs workflows / userType du Sctype104 ci-dessous</li>
            <li>Reporter le / les cootype(s) champs defaulType du Sctype88 ci-dessous</li>
            <li>Passer le flag de la conotion à 1</li>
          </ol>
        </div>
        <TextInput source="id" label="conid" disabled/>
        <TextInput source="nocore" label="nocore" disabled/>
        <SelectInput source="noflag" choices={flagFilter} optionValue="_id" label="flag"/>
        <TextInput helperText={helperTextNoname} source="noname" label="noname" validate={validateNoname}/>
        <JsonInput source="server88" validate={validateScvalueJson88} label="Sctype 88" helperText={helperTextSctype88}/>
        <JsonInput source="server104" validate={validateScvalueJson104} label="Sctype 104" helperText={helperTextSctype104}/>
        {/* <ApproveButton /> */}
      </FormTab>
      <FormTab label='coobjecttypes' >
        <ReferenceArrayField label="Labels" reference="cotypes" source="cotid">
          <Datagrid>
            <TextField source="id" label="Cotid"/>
            <NumberField source="cotname" label="Cotype name" />
            <NumberField source="cotflag" label="Flag"/>
            <TextField source="cotnotionid" label="Cotnotionid" />
            <DeleteButton />
          </Datagrid>
        </ReferenceArrayField>
        <AddcoobjecttypeButton />
      </FormTab>
      <FormTab label='codescritiontypes'>
        <ReferenceArrayField label="Labels" reference="codescriptiontypes" source="codtid">
          <Datagrid>
            <NumberField source="id" label="Id"/>
            <ReferenceField source="codtnotionid" reference="conotions" label="Notion name">
              <TextField source="noname" label="name" />
            </ReferenceField>
            <NumberField source="codtflag" label="Flag"/>
            <TextField source="codtname" label="Codtname" />
            <DeleteButton />
          </Datagrid>
        </ReferenceArrayField>
        <AddcodescriptiontypeButton />
      </FormTab>
      <FormTab label='codusertypes'>
        <ReferenceArrayField label="Labels" reference="codusertypes" source="coutid">
          <Datagrid>
            <NumberField source="id" label="Id"/>
            <TextField source="couttname" label="OC user type name" />
            <ReferenceField source="coutnotionid" reference="conotions" label="Notion name">
              <TextField source="noname" label="name" />
            </ReferenceField>
            <NumberField source="coutflag" label="Flag"/>
            <DeleteButton />
          </Datagrid>
        </ReferenceArrayField>
        <AddcodusertypeButton />
      </FormTab>
    </TabbedForm>
  </Edit>
)}


export default ConotionEdit;
