import React, { useState } from 'react';
import Card from '@material-ui/core/Card';
import { Title } from 'react-admin';

import BadgeList from './BadgeList'
import Filters from './Filters'


const BadgeManagement = () => {
  const [marketId, setMarketId] = useState(null)
  const [brandId, setBrandId] = useState(null)

    return (
        <Card>
            <Title title="BadgeManagement" />
              <Filters
                setMarketId={setMarketId}
                setBrandId={setBrandId}
              />
              {marketId && brandId ? <BadgeList marketId={marketId} brandId={brandId} /> : null}
        </Card>
    )
}

export default BadgeManagement;