import TagsMapOneToManyList from './views/tagsmapOnetomanyList'
import TagsMapOneToManyEdit from './views/tagsmapOnetomanyEdit'
import TagsMapOneToManyCreate from './views/tagsmapOneToManyCreate'

export default {
  name:"tagsmap-onetomany",
  list:TagsMapOneToManyList,
  edit:TagsMapOneToManyEdit,
  create:TagsMapOneToManyCreate
}


