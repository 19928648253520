import React from 'react';
import {  Edit, SimpleForm, NumberInput, ReferenceInput, AutocompleteInput, SelectInput } from 'react-admin';
import Toolbar from '../../CustomLayout/Components/Toolbar';
import { auditTypes, flagFilter } from '../../const/const';
import CustomNumberFieldRedirectToDoc from '../../CustomLayout/Components/CustomNumberFieldRedirectToDoc';
import {validatePositiveNumber} from '../../../utils/validation'

const LearningBadgeDocumentEdit = props => (
  <Edit {...props}>
    <SimpleForm toolbar={<Toolbar />}>
      <NumberInput source="id" label="id" disabled/>
      <span>v Click here to see related docs</span>
      <CustomNumberFieldRedirectToDoc source="lbddoid" label="Document id"/>
      <NumberInput source="lbddoid" label="lbddoid" validate={validatePositiveNumber}/>
      <ReferenceInput source="lbdlbid" reference="learningbadges" label="Badge" filterToQuery={searchText => ({ lbtitle: searchText })}>
        <AutocompleteInput optionText="lbtitle"/>
      </ReferenceInput>
      <SelectInput source="lbdaudittype" choices={auditTypes} optionValue="_id" label="Audit Type"/>
      <NumberInput source="lbdaudittype" label="Audit Type Id" validate={validatePositiveNumber}/>
      <NumberInput source="lbdscore" label="Score" />
      <SelectInput source="lbdflag" choices={flagFilter} optionValue="_id" label='flag'/>
    </SimpleForm>
  </Edit>
);

export default LearningBadgeDocumentEdit;
