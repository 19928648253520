import React from 'react';
import { NumberInput, TextInput, Edit, SimpleForm, ReferenceInput, SelectInput } from 'react-admin';
import Toolbar from '../../CustomLayout/Components/Toolbar';
import { flagFilter } from '../../const/const';

const CotypeEdit = props => (
  <Edit {...props}>
      <SimpleForm toolbar={<Toolbar />}>
        <NumberInput source="id" label="Id" disabled/>
        <TextInput source="cotname" label="CO type name" required/>
        <ReferenceInput label="notion" source="cotnotionid" reference="conotions">
          <SelectInput optionText="noname"/>
        </ReferenceInput>
        <SelectInput source="cotflag" choices={flagFilter} optionValue="_id" />
      </SimpleForm>
  </Edit>
);

export default CotypeEdit;
