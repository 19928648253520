import DocumentList from './views/DocumentList'
import DocumentEdit from './views/DocumentEdit'
import DocumentCreate from './views/DocumentCreate'

export default {
  name:"documents",
  list:DocumentList,
  edit:DocumentEdit,
  create:DocumentCreate,
}
