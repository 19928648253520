import { createMuiTheme } from '@material-ui/core/styles';
import red from '@material-ui/core/colors/red';
import blue from '@material-ui/core/colors/blue';
import lightBlue from '@material-ui/core/colors/lightBlue';
import grey from '@material-ui/core/colors/grey';
import teal from '@material-ui/core/colors/teal';

const myRedTheme = createMuiTheme({
  palette: {
      primary: red,
      secondary: red,
      error: red,
  },
})

const myBlueTheme = createMuiTheme({
  palette: {
      primary: blue,
      secondary: lightBlue,
      error: red,
  },
})

const myGreenTheme = createMuiTheme({
  palette: {
      primary: grey,
      secondary: teal,
      error: red,
  },
})

const myBlackTheme = createMuiTheme({
  palette: {
      primary: {main: '#000000'},
      secondary: grey,
      error: red,
  },
})

const getTheme = () => {
  const envName = localStorage.getItem('env-name');
  if (!envName) return myBlueTheme;
  const envNameSplit = envName.split('-');
  if (!envNameSplit.length)
    return myBlueTheme;
  const index = envNameSplit.length - 1;
  const envLevel = envNameSplit[index]
  if (envName === 'local-dev')
    return myBlackTheme;
  if (envLevel === 'preprod')
    return myGreenTheme;
  if (envLevel === 'prod')
    return myRedTheme;
  return myBlueTheme;
}

export default getTheme;