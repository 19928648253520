import React from 'react';
import { List, Datagrid, TextField, NumberField, NumberInput, Filter, TextInput, SelectInput, SelectField, Pagination } from 'react-admin';
import { flagFilter, paginationRow } from '../../const/const'

const CoserverconfigFilters = (props) => (
  <Filter {...props}>
      <NumberInput source="id" lable="Search by sctype" />
      <SelectInput source="scflag" choices={flagFilter} optionValue="_id" label="Search by flag"/>
      <TextInput source="sctname" lable="Search by Name" />
      <TextInput source="scformat" lable="Search by format" />
  </Filter> 
);

const NewPagination = props => <Pagination rowsPerPageOptions={paginationRow} {...props} />;

const CoserverconfigList = props => (
  <List filters={<CoserverconfigFilters />} {...props}  bulkActionButtons={false} pagination={<NewPagination />}>
      <Datagrid rowClick="edit">
          <NumberField source="id" label="Id"/>
          <TextField source="sctname" label="Name"/>          
          <SelectField source="scflag" choices={flagFilter} optionValue="_id" label="Flag"/>
          <TextField source="scformat" label="Format" />
          <TextField source="scdescription" label="Description" />
      </Datagrid>
  </List>
);

export default CoserverconfigList;