import { gql } from '@apollo/client';

const docQuery = gql`
query getDocumentsForBadge ($intersectTags: [QueryInputMultipleTags!]){
    admin {
        documents(tagMultipleIntersectIds: $intersectTags, workflows: [PUBLISHED, DRAFT], type: lesson, limit: 100) {
            docId
            versions {
                title
                workflow
                lang
            }
        }
    }
}
`;

const removeLinkMutation = gql`
mutation removeDocumentsFromBadges ($docId: Int!, $badgeId: Int!){
    admin {
        removeDocumentsFromBadges(data: [{
            docId: $docId,
            badgeId: $badgeId,
        },
        ])
    }
}
`;

const updateReadMutation = gql`
mutation updateBadgeDocument ($docId: ID!, $badgeId: ID!){
    admin {
        updateBadgeDocument(
            docId: $docId,
            badgeId: $badgeId,
            audit: 2,
        )
    }
}
`;

const updateActivityMutation = gql`
mutation updateBadgeDocument ($docId: ID!, $badgeId: ID!){
    admin {
        updateBadgeDocument(
            docId: $docId,
            badgeId: $badgeId,
            audit: 4,
        )
    }
}
`;

export {
    docQuery,
    removeLinkMutation,
    updateReadMutation,
    updateActivityMutation,
} 