import React from 'react';
import { SimpleForm, TextInput, SelectInput, BooleanInput, Create, NumberInput } from 'react-admin';
import { statusOptions, tagSourceOptions, flagFilter } from '../../const/const';

const validateWeight = (value) => value < 0 ? "Weight must be positive or zero" : null

const TagCreate = props => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="taname" label="name"/>
      <NumberInput initialValue={1} source='taweight' label='weight' validate={validateWeight}/>
      <TextInput source="tadescription" label="description"/>
      <TextInput source="tapic" label="pic"/>
      <TextInput source="tasearchable" label="searchable"/>
      <SelectInput initialValue={3} source="tastatusid" choices={statusOptions} optionText="name" label="status" optionValue="_id"/>
      <BooleanInput initialValue={false} source='tafeature' label='feature'/>
      <SelectInput initialValue={1} source="taflag" choices={flagFilter} optionValue="_id" label='flag'/>
      <NumberInput initialValue={0} source='taruledisplay' label='ruleDisplay'/>
      <NumberInput initialValue={0} source='taorder' label='order'/>
      <BooleanInput initialValue={false} source='taappfeature' label='appFeature'/>
      <BooleanInput initialValue={false} source='taappmock' label='appMock'/>
      <NumberInput initialValue={0} source='tarulemanage' label='ruleManage'/>
      <SelectInput initialValue={1} source="tasource" choices={tagSourceOptions} optionText="name" label="source" optionValue="_id"/>
    </SimpleForm>
  </Create>
);

export default TagCreate;
