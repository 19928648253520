import React from 'react'
import Button from '@material-ui/core/Button';

export default class Nlp extends React.Component {

    state = {
        responseNlp: false,
        sentenceStruct: false,
        raw: '',
        data: {}
    }

    sendNlp = async (raw) => {
      const response = await fetch(`${process.env.REACT_APP_PROXY_SERVER_URL}nlp/en`, {
          method: 'POST',
          headers: {
            env: localStorage.getItem('env-name'),
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            Authorization: localStorage.getItem('token'),
          }, 
          body: JSON.stringify({ raw })
        });
        try {
        const data = await response.json();
        this.setState({responseNlp: true, data: data.msg.sentenceStruct})
        }
        catch (e) {
          alert('error while reaching nlp', e)
        }
        
    }
    render(){
        return(
            <div>
                {this.state.responseNlp ? (
          <div>
            <div>
              <form onSubmit={this.sendNlp}>
              <input type="text" placeholder="Your raw text here" name="raw" value={this.state.raw} onChange={(event)=>this.setState({raw: event.target.value})} required="true"/>
              <Button variant="outlined" color="primary" style={{margin: 10 , height: 40}} onClick={()=>this.sendNlp(this.state.raw)}>
                  Send NLP
              </Button>
              </form>
            </div>
            {JSON.stringify(this.state.data, null, 2)}
            </div>
          ): (
            <div>
              <form onSubmit={this.sendNlp}>
              <input type="text" placeholder="Your raw text here" name="raw" value={this.state.raw} onChange={(event)=>this.setState({raw: event.target.value})} required="true"/>
              <Button variant="outlined" color="primary" style={{margin: 10 , height: 40}} onClick={()=>this.sendNlp(this.state.raw)}>
                  Send NLP
              </Button>
              </form>
            </div>
            )}
            </div>
        )}
}