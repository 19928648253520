import UserTagEdit from './views/UserTagEdit'
import UserTagCreate from './views/UserTagCreate'
import UserTagList from './views/UserTagList'

export default {
  name:"usertags",
  edit:UserTagEdit,
  create:UserTagCreate,
  list: UserTagList,
}
