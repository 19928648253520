export const statusOptions = [
  { _id: 1, name: 'ACTIVE'},
  { _id: 2, name: 'INACTIVE'},
  { _id: 3, name: 'DRAFT'},
  { _id: 4, name: 'DUPLICATE'},
  { _id: 5, name: 'ARCHIVED'},
  { _id: 6, name: 'REJECT'},
]

export const flagOptions = [
  { _id: 1, name: 'ACTIVE'},
  { _id: 2, name: 'UPDATED'},
  { _id: 3, name: 'DELETED'},
]

export const typeOptions = [
  { _id: 0, name: 'NONE'},
  { _id: 1, name: 'HUMAN'},
  { _id: 2, name: 'BOT'},
  { _id: 3, name: 'ADMINISTRATOR'},
]

export const langueOption = [
  { _id: 0,	name: 'NO LANGUAGE' },
  { _id: 1,	name: 'FRENCH' },
  { _id: 2,	name: 'ENGLISH' },
  { _id: 3,	name: 'SPANISH' },
  { _id: 4,	name: 'GERMAN' },
  { _id: 5,	name: 'ITALIAN' },
  { _id: 6,	name: 'DANISH' },
  { _id: 7,	name: 'DUTCH' },
  { _id: 8,	name: 'FINNISH' },
  { _id: 9,	name: 'HUNGARIAN' },
  { _id: 10, name: 'NORWEGIAN' },
  { _id: 11, name: 'PORTUGUESE' },
  { _id: 12, name: 'ROMANIAN' },
  { _id: 13, name: 'RUSSIAN' },
  { _id: 14, name: 'SWEDISH' },
  { _id: 15, name: 'TURKISH' },
  { _id: 16, name: 'CHINESE' },
  { _id: 17, name: 'SIMPLIFIEDCHINESE' },
  { _id: 18, name: 'KOREAN' },
  { _id: 19, name: 'JAPANESE' },
  { _id: 20, name: 'ARABIC' },
  { _id: 21, name: 'PERSIAN' },
]

export const descriptionType = [
  { _id: 0, name: 'other'},
  { _id: 1, name: 'mail'},
  { _id: 2, name: 'phoneNumber'},
  { _id: 3, name: 'pic'},
  { _id: 4, name: 'link'},
  { _id: 5, name: 'firstName'},
  { _id: 6, name: 'lastName'},
  { _id: 7, name: 'picLink'},
  { _id: 8, name: 'nickName'},
  { _id: 9, name: 'timezone'},
  { _id: 10, name: 'age'},
  { _id: 11, name: 'localization'},
  { _id: 12, name: 'lang'},
  { _id: 13, name: 'gender'},
  { _id: 14, name: 'resume'},
  { _id: 15, name: 'seniorityDate'},
  { _id: 16, name: 'position'},
  { _id: 17, name: 'city'},
  { _id: 18, name: 'inviteCode'},
  { _id: 19, name: 'homeBuilding'},
  { _id: 20, name: 'homeStreet'},
  { _id: 21, name: 'pointOfSales'},
  { _id: 22, name: 'loyaltyPoints'},
  { _id: 23, name: 'homeZip'},
  { _id: 24, name: 'homeCity'},
  { _id: 25, name: 'homeCountry'},
  { _id: 26, name: 'birthday'},
  { _id: 27, name: 'prefixPhone'},
  { _id: 28, name: 'phoneNumber2'},
  { _id: 29, name: 'posAddress'},
  { _id: 30, name: 'prefixPhone2'},
]

export const tagSourceOptions = [
  { _id: 1, name: 'CONFIG'},
  { _id: 2, name: 'LEARNING'},
  { _id: 3, name: 'ML'},
  { _id: 4, name: 'USER'},
  { _id: 5, name: 'ADMIN'},
]

export const tagType = [
  { _id: 0, name: 'NOTYPE'},
  { _id: 1, name: 'SKILL'},
  { _id: 2, name: 'INTEREST'},
]

export const methodOption = [
  { _id: 0, name: 'NONE'},
  { _id: 1, name: 'GET'},
  { _id: 2, name: 'POST'},
  { _id: 3, name: 'PUT'},
  { _id: 4, name: 'DELETE'},
  { _id: 5, name: 'PATCH'},
]

export const flagFilter = [
  { _id: 1, name: '1'},
  { _id: 2, name: '2'},
  { _id: 3, name: '3'},
]

export const paginationRow = [
  10,
  25,
  50,
  100,
]

export const documentTypes = [
  { _id: 0, name: 'NONE'},
  { _id: 1, name: 'question'},
  { _id: 2, name: 'answer'},
  { _id: 3, name: 'degree'},
  { _id: 4, name: 'program'},
  { _id: 5, name: 'lesson'},
  { _id: 6, name: 'insight'},
  { _id: 7, name: 'card'},
  { _id: 8, name: 'story'},
  { _id: 9, name: 'external'},
  { _id: 10, name: 'memo'},
  { _id: 11, name: 'battle'},
  { _id: 12, name: 'poll'},
]

export const auditTypes = [
  { _id: 1, name: 'vote' },
  { _id: 2, name: 'read' },
  { _id: 3, name: 'bookmark' },
  { _id: 4, name: 'activity' },
  { _id: 5, name: 'answer' },
  { _id: 6, name: 'comment' },
  { _id: 7, name: 'validate' },
  { _id: 8, name: 'create' },
  { _id: 9, name: 'share' },
  { _id: 10, name: 'update' },
  { _id: 11, name: 'delete' },
  { _id: 12, name: 'battle' },
  { _id: 13, name: 'win' },
  { _id: 14, name: 'download' },
  { _id: 15, name: 'copy' },
  { _id: 16, name: 'report' },
  { _id: 17, name: 'publish' },
  { _id: 18, name: 'createMemo' },
]

export const languages = [
  { _id: 0, name: 'NO LANGUAGE'},
  { _id: 1, name: 'FRENCH'},
  { _id: 2, name: 'ENGLISH'},
  { _id: 3, name: 'SPANISH'},
  { _id: 4, name: 'GERMAN'},
  { _id: 5, name: 'ITALIAN'},
  { _id: 6, name: 'DANISH'},
  { _id: 7, name: 'DUTCH'},
  { _id: 8, name: 'FINNISH'},
  { _id: 9, name: 'HUNGARIAN'},
  { _id: 10, name: 'NORWEGIAN'},
  { _id: 11, name: 'PORTUGUESE'},
  { _id: 12, name: 'ROMANIAN'},
  { _id: 13, name: 'RUSSIAN'},
  { _id: 14, name: 'SWEDISH'},
  { _id: 15, name: 'TURKISH'},
  { _id: 16, name: 'CHINESE'},
  { _id: 17, name: 'SIMPLIFIEDCHINESE'},
  { _id: 18, name: 'KOREAN'},
  { _id: 19, name: 'JAPANESE'},
  { _id: 20, name: 'ARABIC'},
  { _id: 21, name: 'PERSIAN'},
]