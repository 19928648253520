import React from 'react';
import { Datagrid, Filter, SelectField, TextField, NumberField, ReferenceField, ReferenceInput, SelectInput, NumberInput, AutocompleteInput, DateField, Pagination, List } from 'react-admin';
import { tagType, paginationRow, flagFilter } from '../../const/const'

const UserTagFilters = (props) => (
  <Filter {...props}>
    <NumberInput label="Search by id" source="utuserid"  />
    <NumberInput label="Search by utid" source="utid"  />
    <ReferenceInput source="utid" reference="tags" label="Search by utag" filterToQuery={searchText => ({ taname: searchText })}>
      <AutocompleteInput optionText="taname"/>
    </ReferenceInput>
    <SelectInput source="utflag" choices={flagFilter} optionValue="_id" label="Search by flag"/>
    <SelectInput source="uttype" choices={tagType} optionValue="_id" label="Search by type"/>
  </Filter>
);

const NewPagination = props => <Pagination rowsPerPageOptions={paginationRow} {...props} />;

const UserTagsList = props => (
  <List {...props} filters={<UserTagFilters/>}  bulkActionButtons={false} pagination={<NewPagination />}>
    <Datagrid rowClick="edit">
      <NumberField source="utuserid" label="utuserid" />
      <ReferenceField source="utid" reference="tags" label="Tag">
        <TextField source="taname" label="name" />
      </ReferenceField>
      <SelectField source="uttype" choices={tagType} optionValue="_id" />
      <NumberField source="utflag" label="flag"/>
      <DateField showTime source="utcreationdate" label="creationDate" />
      <DateField showTime source="utupdatedate" label="updateDate" />
      <TextField source='utupdatesource' label='utupdatesource' />
    </Datagrid>
  </List>
);

export default UserTagsList;
