import LearningBadgeList from './views/LearningBadgeList'
import LearningBadgeEdit from './views/LearningBadgeEdit'
import LearningBadgeCreate from './views/LearningBadgeCreate'

export default {
  name:"learningbadges",
  list:LearningBadgeList,
  edit:LearningBadgeEdit,
  create:LearningBadgeCreate,
}
