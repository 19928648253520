import React from 'react';
import { List, Datagrid, NumberField, TextField, Filter, TextInput, SelectInput, SelectField, ReferenceField, Pagination } from 'react-admin';
import { flagFilter, paginationRow } from '../../const/const'

const NewPagination = props => <Pagination rowsPerPageOptions={paginationRow} {...props} />;

const CodusertypeFilters = (props) => (
  <Filter {...props}>
      <TextInput label="Search by name" source="nocore"  />
      <SelectInput source="coutflag" choices={flagFilter} optionValue="_id" label="Search by flag"/>
  </Filter> 
);

const CodusertypeList = props => (
  <List filters={<CodusertypeFilters />} {...props}  bulkActionButtons={false} pagination={<NewPagination />}>
      <Datagrid rowClick="edit">
          <NumberField source="id" label="Id"/>
          <TextField source="couttname" label="OC user type name" />  
          <ReferenceField source="coutnotionid" reference="conotions" label="Notion name">
            <TextField source="noname" label="name" />
          </ReferenceField>
          <SelectField source="coutflag" choices={flagFilter} optionValue="_id" />
      </Datagrid>
  </List>
);




export default CodusertypeList;