import LearningBadgeRangeList from './views/LearningBadgeRangeList'
import LearningBadgeRangeEdit from './views/LearningBadgeRangeEdit'
import LearningBadgeRangeCreate from './views/LearningBadgeRangeCreate'

export default {
  name:"learningbadgeranges",
  list:LearningBadgeRangeList,
  edit:LearningBadgeRangeEdit,
  create:LearningBadgeRangeCreate,
}
