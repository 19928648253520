import React from 'react';
import { SimpleForm, TextInput, SelectInput, Create, NumberInput } from 'react-admin';
import JsonInput from '../../../utils/JsonInput';
import { flagFilter } from '../../const/const';
import {jsonValidation, validatePositiveNumber} from '../../../utils/validation'

const validateScvalueJson = [jsonValidation]

const CotypeCreate = props => {
  return (
    <Create {...props} >
    <SimpleForm>
        <NumberInput source="sctype" label="Type id" validate={validatePositiveNumber}/>
        <SelectInput source="scflag" choices={flagFilter} optionValue="_id" />
        <TextInput source="scvalue" label="Value" />
        <JsonInput source="scvaluejson" validate={validateScvalueJson}/>
      </SimpleForm>
    </Create>
  );
}

export default CotypeCreate;
