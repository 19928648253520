import React from 'react';
import { Datagrid, TextField, List, ReferenceField, Filter, NumberField, Pagination, DateField, NumberInput } from 'react-admin';
import {paginationRow} from '../../const/const';
import CustomNumberFieldRedirectToDoc from '../../CustomLayout/Components/CustomNumberFieldRedirectToDoc'

const UploadFilters = (props) => (
  <Filter {...props}>
      <NumberInput source="id" label="Id"/>
      {/* <TextInput source="client" label="client"/>
      <TextInput source="env" label="env"/>
      <TextInput source="url" label="url"/>
      <TextInput source="key" label="key"/> */}
  </Filter>
);

const NewPagination = props => <Pagination rowsPerPageOptions={paginationRow} {...props} />;

const UploadsList = props => ( 
  <List filters={<UploadFilters/>} {...props}  bulkActionButtons={false} pagination={<NewPagination />}>
      <Datagrid rowClick="edit">
        <NumberField source="id" label="upid"/>
        <DateField showTime source="update" label="update"/>
        <TextField source="uptitle" label="uptitle"/>
        <ReferenceField source="uptaid" reference="tags" label="uptaid">
          <TextField source="taname" label="name" />
        </ReferenceField>
        <NumberField source="uptaid" label="uptaid"/>
        <CustomNumberFieldRedirectToDoc source="updoid" label="updoid"/>
        <ReferenceField source="upcoid" reference="coreobjects" label="Boutique">
          <TextField source="coname" label="name" />
        </ReferenceField>
        <ReferenceField source="upusid" reference="users" label="User">
          <ReferenceField label="Email" source="descriptionMailid" reference="descriptions">
            <TextField source="delink"/>
          </ReferenceField>
        </ReferenceField>
        <TextField source="upvmids" label="upvmids"/>
        <TextField source="upenv" label="Env"/>
        <DateField showTime source="upcreationdate" label="upcreationdate"/>
      </Datagrid>
  </List>
);




export default UploadsList;
