import { gql } from '@apollo/client';

const changeConfigToken = gql`
mutation changeFacebookConfigurationToken ($facebookToken: String!) {
  admin {
      changeFbTokenIntoConfig(accessToken: $facebookToken)
  }
}
`;

export {
  changeConfigToken,
}