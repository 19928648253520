import { gql } from '@apollo/client';

const marketQuery = gql`
  query getTags {
    tags(clusterIds: [2]) {
      tagId
      clusterId
      title
    }
  }
`;

const brandQuery = gql`
  query getTags {
    tags(clusterIds: [18]) {
      tagId
      clusterId
      title
    }
  }
`;

export {
    marketQuery,
    brandQuery,
}