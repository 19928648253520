import React from 'react';
import {  Create, SimpleForm, NumberInput, TextInput, DateInput, SelectInput } from 'react-admin';
import { documentTypes, languages, flagFilter } from '../../const/const';
import {validatePositiveNumber} from '../../../utils/validation'

const DocumentCreate = props => {
  return (
    <Create {...props} >
        <SimpleForm>
          <NumberInput source="doid" label="doid" validate={validatePositiveNumber}/>
          <NumberInput source="doparentid" label="Parentid" validate={validatePositiveNumber}/>
          <SelectInput source="dotype" choices={documentTypes} optionValue="_id" label="Type"/>
          <TextInput source="dotitle" label="Titre" />
          <TextInput source="dolink" label="Link" />
          <NumberInput source="dotemplate" label="Template" />
          <TextInput source="doupdatesource" label="Update Source" />
          <DateInput showTime source="doupdatedate" label="updatedate" disabled/>
          <SelectInput source="doflag" choices={flagFilter} optionValue="_id" label='flag'/>
          <NumberInput source="domdfid" label="domdfid" validate={validatePositiveNumber}/>
          <SelectInput source="dolanguage" choices={languages} optionValue="_id" label="Language"/>
          <NumberInput source="doclonedfromid" label="CloneFromId" validate={validatePositiveNumber}/>
      </SimpleForm>
    </Create>
  );
}

export default DocumentCreate;
