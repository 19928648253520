import React from 'react';
import {Edit, SimpleForm, ReferenceInput, required, NumberInput, AutocompleteInput} from 'react-admin';
import Toolbar from '../../CustomLayout/Components/Toolbar';

const TagsmapOneToOneEdit = props => (
  <Edit {...props}>
    <SimpleForm toolbar={<Toolbar />}>
      <ReferenceInput
        label="tag 1"
        source="tamatid"
        reference="tags"
        filterToQuery={searchText => ({"taname": searchText})}
        validate={[required()]}>
        <AutocompleteInput optionText="taname"/>
      </ ReferenceInput>
      <ReferenceInput
        label="tag 2"
        source="tamtacopy"
        reference="tags"
        filterToQuery={searchText => ({"taname": searchText})}
        validate={[required()]}>
        <AutocompleteInput optionText="taname"/>
      </ ReferenceInput>
      <NumberInput source='tamflag' label='flag'/>
    </SimpleForm>
  </Edit>
);

export default TagsmapOneToOneEdit;
