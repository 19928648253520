export default theme => ({
    formControl: {
      margin: theme.spacing(2),
      minWidth: 120,
    },
    selectEmpty: {
      //marginTop: theme.spacing(2),
      borderTopLeftRadius: '4px',
      borderTopRightRadius: '4px',
      overflow: 'hidden',
      paddingLeft: theme.spacing(1),
      backgroundColor: 'white',
      textTransform: 'uppercase'
    },
    menuItem: {
      textTransform: 'uppercase'
    }
});