import React from 'react'
import Editor from 'react-simple-code-editor';
import { highlight, languages } from 'prismjs/components/prism-core';
import 'prismjs/components/prism-clike';
import 'prismjs/components/prism-javascript';
import 'prismjs/components/prism-json';
import { useInput } from 'ra-core';

const JsonInput = (props) => {
  const {
    input: { value, onChange },
    meta: { error },
  } = useInput({...props, type: 'text'});
  const { helperText } = props;

  return (
    <div style={{border: "1px solid #E7E3E3", padding: "2%"}}>
      <div style={{"fontWeight": "bold"}} >{props.label}</div>
      {error && (<div style={{"fontWeight": "bold", color: "red", paddingTop: "1%"}}>{error}</div>) }
      {helperText && (<div style={{color: "orange", padding: "1%"}}>{helperText}</div>) }
      <Editor
        value={value}
        onValueChange={code => onChange(code)}
        highlight={code => highlight(code, languages.json)}
        padding={10}
        style={{
          fontFamily: '"Fira code", "Fira Mono", monospace',
          fontSize: 12,
          border: "1px solid #E7E3E3",
        }}
      />
    </div>
  );
}
export default JsonInput
