import LearningBadgeDocumentList from './views/LearningBadgeDocumentList'
import LearningBadgeDocumentEdit from './views/LearningBadgeDocumentEdit'
import LearningBadgeDocumentCreate from './views/LearningBadgeDocumentCreate'

export default {
  name:"learningbadgedocuments",
  list:LearningBadgeDocumentList,
  edit:LearningBadgeDocumentEdit,
  create:LearningBadgeDocumentCreate,
}
