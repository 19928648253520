import TagsMapOneToOneList from './views/tagsmapOneToOneList'
import TagsMapOneToOneEdit from './views/tagsmapOneToOneEdit'
import TagsMapOneToOneCreate from './views/tagsmapOneToOneCreate'

export default {
  name:"tagsmap-onetoone",
  list:TagsMapOneToOneList,
  edit:TagsMapOneToOneEdit,
  create:TagsMapOneToOneCreate
}


