import ConotionList from './views/ConotionList'
import ConotionEdit from './views/ConotionEdit'
import ConotionCreate from './views/ConotionCreate'

export default {
  name:"conotions",
  list:ConotionList,
  edit:ConotionEdit,
  create:ConotionCreate,
}
