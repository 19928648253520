import CotypeList from './views/CotypeList'
import CotypeEdit from './views/CotypeEdit'
import CotypeCreate from './views/CotypeCreate'

export default {
  name:"cotypes",
  list:CotypeList,
  edit:CotypeEdit,
  create:CotypeCreate,
}
