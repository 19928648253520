import React from 'react';
import {  Create, SimpleForm, NumberInput, ReferenceInput, AutocompleteInput, SelectInput } from 'react-admin';
import { parse } from "query-string";

import { auditTypes, flagFilter } from '../../const/const';
import {validatePositiveNumber} from '../../../utils/validation'

const LearningBadgeDocumentCreate = props => {
  const { lbdlbid: lbdlbid_string } = parse(props.location.search);
  const lbdlbid = lbdlbid_string ? parseInt(lbdlbid_string, 10) : "";
  const defaultValue = {
    lbdlbid,
    lbdaudittype: 1,
    lbdscore: 0,
    lbdflag: 1,
  }
  return (
    <Create {...props} >
      <SimpleForm defaultValue={defaultValue} redirect={lbdlbid ? `/learningbadges/${lbdlbid}/1` : '/learningbadgedocuments'}>
        <NumberInput source="lbddoid" label="lbddoid (docid)" validate={validatePositiveNumber}/>
        <ReferenceInput source="lbdlbid" reference="learningbadges" label="Badge" filterToQuery={searchText => ({ lbtitle: searchText })}>
          <AutocompleteInput optionText="lbtitle"/>
        </ReferenceInput>
        <NumberInput source="lbdlbid" label="Badgeid" validate={validatePositiveNumber}/>
        <SelectInput source="lbdaudittype" choices={auditTypes} optionValue="_id" label="Audit Type"/>
        <NumberInput source="lbdaudittype" label="Audit Type Id" validate={validatePositiveNumber}/>
        <NumberInput source="lbdscore" label="Score" />
        <SelectInput source="lbdflag" choices={flagFilter} optionValue="_id" label='flag'/>
      </SimpleForm>
    </Create>
  );
}

export default LearningBadgeDocumentCreate;
