import React from 'react';
import { Create, SimpleForm, TextInput, NumberInput, BooleanInput, ReferenceInput, AutocompleteInput, SelectInput } from 'react-admin';
import {flagFilter} from '../../const/const'
import {validatePositiveNumber} from '../../../utils/validation'

const ClusterCreate = props => (
  <Create {...props}>
      <SimpleForm>
        <TextInput initialValue="" source="clname" label="name" />
        <BooleanInput initialValue={false} source="cltype" label="type" />
        <ReferenceInput source="clmetatag" reference="tags" label="Metatag" filterToQuery={searchText => ({ taname: searchText })}>
          <AutocompleteInput optionText="taname"/>
        </ReferenceInput>
        <NumberInput initialValue={0} source="clparentid" label="parentId" validate={validatePositiveNumber}/>
        <SelectInput initialValue={1} source="clflag" choices={flagFilter} optionValue="_id" label="flag"/>
        <NumberInput initialValue={0} source="cldeepness" label="deepness" validate={validatePositiveNumber}/>
      </SimpleForm>
  </Create>
);

export default ClusterCreate;