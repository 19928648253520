import React from 'react';
import { TextInput, Edit, SimpleForm, ReferenceInput, SelectInput, NumberInput } from 'react-admin';
import Toolbar from '../../CustomLayout/Components/Toolbar';
import {flagFilter} from '../../const/const'
import {validatePositiveNumber} from '../../../utils/validation'

const CodusertypeEdit = props => (
  <Edit {...props}>
      <SimpleForm toolbar={<Toolbar />}>
        <NumberInput source="id" label="Id" disabled/>
        <TextInput source="couttname" label="Couttname" />
        <ReferenceInput label="notion" source="coutnotionid" reference="conotions">
          <SelectInput optionText="noname"  />
        </ReferenceInput>
        <NumberInput source="coutrole" label="coutrole" validate={validatePositiveNumber}/>
        <SelectInput source="coutflag" choices={flagFilter} optionValue="_id" label="flag"/>
      </SimpleForm>
  </Edit>
);

export default CodusertypeEdit;
