import { gql } from '@apollo/client';

const badgeQuery = gql`
query getBadges ($tags: [ID!]){
    admin {
        badges(tagIds: $tags) {
            title
            badgeId
            pic
            ranges {
                rangeId
                score
            }
            degreeRef {
                title
                docId
            }
            documents(preferredLang: en) {
                docId
                title
                workflow
            }
            badgeDocuments {
                docId
                badgeId
                auditTypeId
                score
            }
        }
    }
}
`;

const updateScoreMutation = gql`
mutation updateScore ($rangeId: ID!, $rangeInput: ID!){
    admin {
        updateRange (
            rangeId: $rangeId,
            score: $rangeInput,
        )
    }
}
`;

const addLessonMutation = gql`
mutation setLessonsToBadges ($docId: Int!, $badgeId: Int!){
    admin {
        setLessonsToBadges(data: [{
            lessonId: $docId,
            badgeId: $badgeId,
            score: 1,
        },
        ])
    }
}
`;

const addQuizMutation = gql`
mutation setQuizToBadges ($docId: Int!, $badgeId: Int!){
    admin {
        setQuizToBadges(data: [{
            quizId: $docId,
            badgeId: $badgeId,
            score: 1,
        },
        ])
    }
}
`;

export {
    badgeQuery,
    updateScoreMutation,
    addLessonMutation,
    addQuizMutation,
}