import React from 'react';
import { SimpleForm, TextInput, Create } from 'react-admin';

const BackofficeUserCreate = props => {
  return (
  <Create {...props}>
      <SimpleForm>
        <TextInput source="name" label="name" />
        <TextInput source="password" label="password" />
      </SimpleForm>
  </Create>
)};

export default BackofficeUserCreate;
