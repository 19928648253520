import axios from 'axios';


export default async (obj, corpusid, grain) => {
    try {
        const data = JSON.stringify({
            "type": "create",
            "grain": grain,
            "corpus_id": corpusid,
            "object": [obj]
        })
        const response = await axios({
            method: 'POST',
            url: `${process.env.REACT_APP_PROXY_SERVER_URL}nlp/Expert/`,
            headers:{
                 env: localStorage.getItem('env-name'),
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                Authorization: localStorage.getItem('token'),
            },
            data: data
        })
        alert(`${grain} added : ${response}`);
    } catch (e) {
        alert(e)
    }
}