import React from "react";
import { MenuItemLink, usePermissions } from 'react-admin';
import { useSelector } from 'react-redux';
import NestedMenuItem from "material-ui-nested-menu-item";

const Menu = ({ onMenuClick, logout }) => {
    const {permissions} = usePermissions();
    const env = localStorage.getItem('env-name');
    const open = useSelector(state => state.admin.ui.sidebarOpen);
    return (
        <div>
            {permissions && permissions.find((role) => (role === 'superAdmin')) &&
              <MenuItemLink to="/" primaryText="Dashboard" onClick={onMenuClick} sidebarIsOpen={open}/>}
            <NestedMenuItem label="Users" parentMenuOpen={true} style={{ color: 'grey' }}>
              <MenuItemLink to="/users" primaryText="Users" onClick={onMenuClick} sidebarIsOpen={open}/>
              <MenuItemLink to="/descriptions" primaryText="Descriptions" onClick={onMenuClick} sidebarIsOpen={open}/>
              <MenuItemLink to="/usertags" primaryText="User Tags" onClick={onMenuClick} sidebarIsOpen={open}/>
            </NestedMenuItem>
            <MenuItemLink to="/clusters" primaryText="Clusters" onClick={onMenuClick} sidebarIsOpen={open}/>
            <MenuItemLink to="/documents" primaryText="Documents" onClick={onMenuClick} sidebarIsOpen={open}/>
            <NestedMenuItem label="Learning Badges" parentMenuOpen={true} style={{ color: 'grey' }}>
              <MenuItemLink to="/learningbadges" primaryText="Learning Badges" onClick={onMenuClick} sidebarIsOpen={open}/>
              <MenuItemLink to="/learningbadgeranges" primaryText="Learning Badges Ranges" onClick={onMenuClick} sidebarIsOpen={open}/>
              <MenuItemLink to="/learningbadgedocuments" primaryText="Learning Badges Document" onClick={onMenuClick} sidebarIsOpen={open}/>
              <MenuItemLink to="/learningbadgetags" primaryText="Learning Badges Tags" onClick={onMenuClick} sidebarIsOpen={open}/>
              <MenuItemLink to="/badgemanagement" primaryText="Learning Badges Management" onClick={onMenuClick} sidebarIsOpen={open}/>
            </NestedMenuItem>
            <NestedMenuItem label="Serveur Config" parentMenuOpen={true} style={{ color: 'grey' }}>
              <MenuItemLink to="/serverconfigs" primaryText="Configs" onClick={onMenuClick} sidebarIsOpen={open}/>
              <MenuItemLink to="/serverconfigtypes" primaryText="Configs Type" onClick={onMenuClick} sidebarIsOpen={open}/>
            </NestedMenuItem>
            <NestedMenuItem label="Core Objects" parentMenuOpen={true} style={{ color: 'grey' }}>
              <MenuItemLink to="/coreobjects" primaryText="Core Objects" onClick={onMenuClick} sidebarIsOpen={open}/>
              <MenuItemLink to="/conotions" primaryText="Conotions" onClick={onMenuClick} sidebarIsOpen={open}/>
              <MenuItemLink to="/cotypes" primaryText="Cotypes" onClick={onMenuClick} sidebarIsOpen={open}/>
              <MenuItemLink to="/codescriptiontypes" primaryText="Codescriptionstypes" onClick={onMenuClick} sidebarIsOpen={open}/>
              <MenuItemLink to="/codusertypes" primaryText="Codusertypes" onClick={onMenuClick} sidebarIsOpen={open}/>
            </NestedMenuItem>
            <NestedMenuItem label="Tags" parentMenuOpen={true} style={{ color: 'grey' }}>
              <MenuItemLink to="/tags" primaryText="Tags" onClick={onMenuClick} sidebarIsOpen={open}/>
              <MenuItemLink to="/taglabels" primaryText="Tag Labels" onClick={onMenuClick} sidebarIsOpen={open}/>
              <MenuItemLink to="/tagGrants" primaryText="Tag Grants" onClick={onMenuClick} sidebarIsOpen={open}/>
              <MenuItemLink to="/tagclusters" primaryText="Tag Cluster" onClick={onMenuClick} sidebarIsOpen={open}/>
              <NestedMenuItem label="Tags Map" parentMenuOpen={true} style={{ color: 'grey' }}>
                <MenuItemLink to="/tagsmap-onetomany" primaryText="Tagsmap One To Many" onClick={onMenuClick} sidebarIsOpen={open}/>
                <MenuItemLink to="/tagsmap-manytoone" primaryText="Tagsmap Many To One" onClick={onMenuClick} sidebarIsOpen={open}/>
                <MenuItemLink to="/tagsmap-onetoone" primaryText="Tagsmap One To One" onClick={onMenuClick} sidebarIsOpen={open}/>
              </NestedMenuItem>
            </NestedMenuItem>
            {['dior-vm-dev', 'dior-vm-preprod', 'dior-vm-prod', 'local-dev'].includes(env) &&
              <MenuItemLink to="/uploads" primaryText="Vm Uploads" onClick={onMenuClick} sidebarIsOpen={open}/>}
            <MenuItemLink to="/logs" primaryText="Logs" onClick={onMenuClick} sidebarIsOpen={open}/>
            {permissions && permissions.find((role) => (role === 'superAdmin')) &&
              <NestedMenuItem label="Admin" parentMenuOpen={true} style={{ color: 'grey' }}>
                <MenuItemLink to="/backofficeUsers" primaryText="Users Backoffice" onClick={onMenuClick} />
                <MenuItemLink to="/roles" primaryText="Roles Backoffice" onClick={onMenuClick} />
                {/* <MenuItemLink to="/configs" primaryText="Config Backoffice" onClick={onMenuClick} /> */}
              </NestedMenuItem>
            }
            {['local-dev', 'shiseido-dev', 'vca-dev', 'shiseido-preprod', 'shiseido-prod', 'vca-prod'].includes(env)
              && permissions && permissions.find((role) => (role === 'superAdmin')) &&
              <NestedMenuItem label="Social Media" parentMenuOpen={true} style={{ color: 'grey' }}>
                <MenuItemLink to="/instagram-login2" primaryText="Instagram" onClick={onMenuClick} sidebarIsOpen={open}/>
              </NestedMenuItem>
            }
            {logout}
        </div>
    );
}

export default Menu;
