import React from 'react';
import { TextInput, Edit, SimpleForm, NumberInput, SelectInput } from 'react-admin';
import Toolbar from '../../CustomLayout/Components/Toolbar';

import { flagFilter } from '../../const/const';

const CotypeEdit = props => {

  return (
  <Edit {...props}>
    <SimpleForm toolbar={<Toolbar />}>
      <NumberInput source="id" label="Id" disabled/>
      <TextInput source="sctname" label="Name"/>
      <TextInput source="scformat" label="format"/>
      <TextInput source="scdescription" label="Description"/>
      <SelectInput source="scflag" choices={flagFilter} optionValue="_id" />
    </SimpleForm>
  </Edit>
)};

export default CotypeEdit;

