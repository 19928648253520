import React, { Component } from 'react'
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import {withStyles} from '@material-ui/styles';
import styles from './style';

class DropDown extends Component {
    state = {
        defaultEnv: localStorage.getItem("env-name") || '',
        envs: {
            "dev" : [],
            "preprod": [],
            "prod" : []
        },
        env: localStorage.getItem("env-name"),
    }
    setEnv = (value) => {
        this.setState({
            env: value,
        });
    }
    handleChange = (event) => {
        this.setEnv(event.target.value);
        localStorage.setItem("env-name", event.target.value);
        window.location.reload();
    };
    componentDidMount = () => {
        fetch(process.env.REACT_APP_PROXY_SERVER_URL + 'envs', {
           method: 'GET',
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
        })
        .then((response) => response.json())
        .then((responseJson) => {
           this.setState({
              envs: responseJson
           })
           if (this.state.defaultEnv === '' && this.state.envs.dev.length > 0) {
                localStorage.setItem('env-name', this.state.envs.dev[0]);
                window.location.reload();
           }
        })
        .catch((error) => {
           console.error(error);
        });
    }
    render() {
        const {classes} = this.props
        return (
            <FormControl className={classes.formControl}>
            <Select
            value={this.state.env}
            defaultValue={this.state.defaultEnv}
            onChange={this.handleChange}
            displayEmpty
            className={classes.selectEmpty}
            inputProps={{ 'aria-label': 'Without label' }}
            >
            <MenuItem value="t1" disabled key="devMenu">
                Developpement
            </MenuItem>
            { this.state.envs.dev.map((env) => (
                <MenuItem className={classes.menuItem} value={env} key={env}>{env}</MenuItem>
            ))}
            <MenuItem value="t2" disabled key="preprodMenu">
                Pre-production
            </MenuItem>
            { this.state.envs.preprod.map((env) => (
                <MenuItem className={classes.menuItem} value={env} key={env}>{env}</MenuItem>
            ))}
            <MenuItem value="t3" disabled key="prodMenu">
                Production
            </MenuItem>
            { this.state.envs.prod.map((env) => (
                <MenuItem className={classes.menuItem} value={env} key={env}>{env}</MenuItem>
            ))}
            </Select>
        </FormControl>)
    }
}

export default withStyles(styles)(DropDown);
