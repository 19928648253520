import React from 'react';
import {  Edit, SimpleForm, NumberInput, SelectInput, ReferenceInput, DateInput } from 'react-admin';
import Toolbar from '../../CustomLayout/Components/Toolbar';
import { flagFilter } from '../../const/const';

const TagEdit = props => (
  <Edit {...props}>
      <SimpleForm toolbar={<Toolbar />}>
          <ReferenceInput source="clclusterid" reference="clusters" label="Cluster">
            <SelectInput optionText="clname" disabled/>
          </ReferenceInput>
          <ReferenceInput source="cltagid" reference="tags" label="Tag">
            <SelectInput optionText="taname" disabled/>
          </ReferenceInput>
          <NumberInput source="clproba" label="Proba" />
          <SelectInput source="clflag" choices={flagFilter} optionValue="_id" label='flag'/>
          <DateInput source="clcreationdate" label="creationDate" disabled/>
          <DateInput source="clupdatedate" label="updatedate" disabled/>
      </SimpleForm>
  </Edit>
);

export default TagEdit;
