import React from 'react';
import { List, Datagrid, TextField, NumberField, ReferenceField, NumberInput, Filter, SelectInput, SelectField, Pagination } from 'react-admin';
import { flagFilter, paginationRow } from '../../const/const'

const CoserverconfigFilters = (props) => (
  <Filter {...props}>
      <NumberInput source="id" lable="Search by id" />
      <SelectInput source="scflag" choices={flagFilter} optionValue="_id" label="Search by flag"/>
      <NumberInput source="sctype" lable="Search by sctype" />
  </Filter> 
);

const NewPagination = props => <Pagination rowsPerPageOptions={paginationRow} {...props} />;

const CoserverconfigList = props => (
  <List filters={<CoserverconfigFilters />} {...props}  bulkActionButtons={false} pagination={<NewPagination />}>
      <Datagrid rowClick="edit">
          <NumberField source="id" label="Id"/>
          <NumberField source="sctype" label="Type id"/>
          <ReferenceField source="sctype" reference="serverconfigtypes" label="sctype Name">
            <TextField source="sctname" label="name" />
          </ReferenceField>
          <SelectField source="scflag" choices={flagFilter} optionValue="_id" />
      </Datagrid>
  </List>
);

export default CoserverconfigList;