import React from 'react';
import {  Edit, SimpleForm, NumberInput, ReferenceInput, SelectInput, DateInput } from 'react-admin';
import Toolbar from '../../CustomLayout/Components/Toolbar';
import { flagFilter } from '../../const/const';
import {validatePositiveNumber} from '../../../utils/validation'

const LearningBadgeTagEdit = props => (
  <Edit {...props}>
    <SimpleForm toolbar={<Toolbar />}>
      <ReferenceInput source="lbttagid" reference="tags" label="Tag Name" filterToQuery={searchText => ({ taname: searchText })}>
        <SelectInput optionText="taname" disabled/>
      </ReferenceInput>
      <ReferenceInput source="lbttagid" reference="tags" label="Tagid" filterToQuery={searchText => ({ taname: searchText })}>
        <SelectInput optionText="id" disabled/>
      </ReferenceInput>
      <ReferenceInput source="lbtlbid" reference="learningbadges" label="Badge" filterToQuery={searchText => ({ lbtitle: searchText })}>
        <SelectInput optionText="lbtitle" disabled/>
      </ReferenceInput>
      <ReferenceInput source="lbtlbid" reference="learningbadges" label="Badgeid" filterToQuery={searchText => ({ lbtitle: searchText })}>
        <SelectInput optionText="id" disabled/>
      </ReferenceInput>
      <NumberInput source="lbtusid" label="User id" validate={validatePositiveNumber}/>
      <SelectInput source="lbtflag" choices={flagFilter} optionValue="_id" label='flag'/>
      <DateInput source="lbtcreationdate" label="creationdate" />
      <DateInput source="lbtupdatedate" label="updatedate" />
    </SimpleForm>
  </Edit>
);

export default LearningBadgeTagEdit;
