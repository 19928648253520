import React from 'react';
import { Edit, SimpleForm, TextInput, ArrayInput, SimpleFormIterator } from 'react-admin';
import Toolbar from '../../CustomLayout/Components/Toolbar';

const BackofficeRoleEdit = props => (
  <Edit {...props}>
    <SimpleForm toolbar={<Toolbar />}>
        <TextInput source="id" label="id" disabled/>
        <ArrayInput source="permissions">
          <SimpleFormIterator>
            <TextInput source="env" label="env"/>
          </SimpleFormIterator>
        </ArrayInput>
    </SimpleForm>
  </Edit>
);

export default BackofficeRoleEdit;
