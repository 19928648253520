import React from 'react';
import { SimpleForm, Edit, TextInput, NumberInput, DateTimeInput, ReferenceInput, AutocompleteInput } from 'react-admin';
import Toolbar from '../../CustomLayout/Components/Toolbar';
import CustomNumberFieldRedirectToDoc from '../../CustomLayout/Components/CustomNumberFieldRedirectToDoc'

const ConfigEdit = props => (
  <Edit {...props}>
    <SimpleForm toolbar={<Toolbar />}>
      <NumberInput source="id" label="id" disabled/>
      <TextInput source="uptitle" label="uptitle" disabled/>
      <DateTimeInput source="update" label="update" disabled/>
      <DateTimeInput source="upcreationdate" label="upcreationdate" disabled/>
      <ReferenceInput source="uptaid" reference="tags" label="Tag" filterToQuery={searchText => ({ taname: searchText })}>
        <AutocompleteInput optionText="taname" disabled/>
      </ReferenceInput>
      <span>v Click here to see related docs parentId</span>
      <CustomNumberFieldRedirectToDoc source="updoid" label="DocId" />
      <NumberInput source="updoid" label="updoid" disabled/>
      <ReferenceInput source="upcoid" reference="coreobjects" label="Boutique" filterToQuery={searchText => ({ cootitle: searchText, coonotionid: 6 })}>
        <AutocompleteInput optionText="cootitle" disabled/>
      </ReferenceInput>
      <NumberInput source="upusid" label="upusid" disabled/>
      <ReferenceInput source="upusid" reference="users" label="Users">
        <ReferenceInput source="deusid" reference="descriptions"filterToQuery={searchText => ({ delink: searchText})}>
          <AutocompleteInput optionText="delink" disabled/>
        </ReferenceInput>
      </ReferenceInput>
      <TextInput source="upvmids" label="upvmids" disabled/>
      <TextInput source="upenv" label="env" disabled/>
    </SimpleForm>
  </Edit>
);

export default ConfigEdit;
