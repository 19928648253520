import React from 'react';
import { Datagrid, TextField, NumberField, DateField, Filter, TextInput, NumberInput, SelectField, SelectInput, List } from 'react-admin';
import { methodOption } from '../../const/const'

const LogFilters = (props) => (
  <Filter {...props}>
    <TextInput source='env' label='env' />
    <TextInput source='domain' label='domain' />
    <TextInput source='user_email' label='user_email' />
    <SelectInput source="method" choices={methodOption} optionValue="_id" label="Search by method"/>
    <NumberInput source='status' label='status' />
  </Filter>
);

const BodyPanel = ({ id, record, resource }) => (
  <div dangerouslySetInnerHTML={{ __html: record.body }} />
);

const LogsList = props => (
  <List {...props} filters={<LogFilters/>} sort={{ field: 'id', order: 'DESC' }} filterDefaultValues={{ env: localStorage.getItem('env-name')}}>
      <Datagrid expand={<BodyPanel/>}>
        <NumberField source="id" label="id" />
        <TextField source='env' label='env' />
        <TextField source='domain' label='domain' />
        <TextField source='user_email' label='user_email' />
        <SelectField source="method" choices={methodOption} optionValue="_id" />
        <DateField showTime source="request_date" label="request_date" />
        <NumberField source="status" label="status" />
        {/* <TextField source='url_path' label='url_path' /> */}
      </Datagrid>
  </List>
);




export default LogsList;
