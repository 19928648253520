import React from 'react';
import {
  SelectInput, Datagrid, TextField, Filter, ReferenceField, AutocompleteInput, ReferenceInput,
  NumberField, BooleanField, DateField, SelectField, Pagination, NumberInput, TextInput, BooleanInput, List, EditButton
} from 'react-admin';
import {typeOptions, langueOption, flagFilter, paginationRow} from '../../const/const'
import DeleteButton from '../../CustomLayout/Components/DeleteButtonWithConfirmation';

const UserFilters = (props) => (
  <Filter {...props}>
      <NumberInput label="Search by id" source="id"  />
      <ReferenceInput source="id" reference="descriptions" label="Mail" filterToQuery={searchText => ({ delink: searchText, detypeid: 1 })}>
        <AutocompleteInput optionText="delink" optionValue="deusid"/>
      </ReferenceInput>
      <SelectInput source="usflag" choices={flagFilter} optionValue="_id" label="Search by flag"/>
      <TextInput label="Search by uspuid" source="uspuid"  />
      <SelectInput source="ustype" choices={typeOptions} optionValue="_id" label="Search by type"/>
      <NumberInput label="Search by descriptionid" source="usdescriptionid"  />
      <BooleanInput label="Search by usanonymous" source="usanonymous"  />
      <BooleanInput label="Search by usruledisplay" source="usruledisplay"  />
      <SelectInput source="uslanguage" choices={langueOption} optionValue="_id" label="Search by langue"/>
  </Filter>
);

const NewPagination = props => <Pagination rowsPerPageOptions={paginationRow} {...props} />;

const UsersList = props => (
  <List filters={<UserFilters/>} bulkActionButtons={false} {...props} pagination={<NewPagination />}>
      <Datagrid>
          <NumberField source="id" label="userId"/>
          <ReferenceField label="Email" source="descriptionMailid" reference="descriptions">
            <TextField source="delink"/>
          </ReferenceField>
          <TextField source="uspuid" label="puid"/>
          <SelectField source="ustype" choices={typeOptions} optionValue="_id" />
          <NumberField source="usdescriptionid" label="descriptionId"/>
          <BooleanField source="usanonymous" label="Anonynous"/>
          <BooleanField source="usruledisplay" label="ruleDisplay"/>
          <NumberField source="usflag" label="flag"/>
          <SelectField source="uslanguage" choices={langueOption} optionValue="_id" label="Language"/>
          <DateField showTime source="uscreationdate" label="creationDate" />
          <DateField showTime source="usupdatedate" label="usupdatedate" />
          <EditButton />
          <DeleteButton />
      </Datagrid>
  </List>
);




export default UsersList;
