import TagLabelList from './views/TagLabelList'
import TagLabelEdit from './views/TagLabelEdit'
import TagLabelCreate from './views/TagLabelCreate'

export default {
  name:"taglabels",
  list:TagLabelList,
  edit:TagLabelEdit,
  create:TagLabelCreate,
}
