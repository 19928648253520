import React from 'react';
import { SimpleForm, TextInput, SelectInput, Create } from 'react-admin';
import { flagFilter } from '../../const/const';

const CotypeCreate = props => {
  return (
    <Create {...props} >
    <SimpleForm>
      <TextInput source="sctname" label="Name"/>
      <TextInput source="scformat" label="format"/>
      <TextInput source="scdescription" label="Description"/>
      <SelectInput source="scflag" choices={flagFilter} optionValue="_id" />
    </SimpleForm>
    </Create>
  );
}

export default CotypeCreate;
