import React from 'react';
import {useRefresh, useRedirect} from 'react-admin';

const CustomNumberFieldRedirectToDoc = (props) => {
  const refresh = useRefresh();
  const redirect = useRedirect();
  
  const { source, record } = props;
  const OnClickDoc = (data) => {
    redirect(`/documents?displayedFilters=%7B"doid"%3Atrue%7D&filter=%7B"doid"%3A${record[source]}%7D&order=ASC&page=1&perPage=10&sort=doid`);
    refresh();
  }
  if (!record)
    return <span />
  return (
    <span style={{color:'blue'}} onClick={OnClickDoc}>{record[source]}</span>
  )
}

export default CustomNumberFieldRedirectToDoc;