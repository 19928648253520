import axios from 'axios';
import decodeJwt from 'jwt-decode';

const login = params => {

  const body = {
    "client_id":"zahRSOQljggJfFfnXgo5CZsO28xldrtX",
    "client_secret":"gag15E233GAH7YZfyS8tkdMeK7lZOz2oTWUUebIQveW1TuX6yFHsFlR6E_s3zCyV",
    "audience":"https://backoffice-moa.eu.auth0.com/api/v2/",
    "grant_type":"http://auth0.com/oauth/grant-type/password-realm",
    "username": params.username,
    "password": params.password,
    "realm":"database",
    "scope": "openid offline_access",
    "device": 'SOMEDEVICE'
  };


  const query = {
    method:'post',
    url:'https://backoffice-moa.eu.auth0.com/oauth/token',
    headers:{
      'content-type': 'application/json'
    },
    data:body
  }

  //axios.post('https://customercare-iobot.eu.auth0.com/oauth/token',body)

  return new Promise((resolve, reject)=> {
    axios(query)
    .then(response => {
      localStorage.setItem('token', response.data.id_token);
      localStorage.setItem('refresh_token', response.data.refresh_token);
      const decodedToken = decodeJwt(response.data.id_token);
      localStorage.setItem('permissions', decodedToken['https://app_metadata'].roles)
      resolve(response.status)
    })
    .catch(e => {
      reject("ra.auth.sign_in_error")
    })

  })
}


const checkError = (error) => {
  const body = {
    "client_id":"zahRSOQljggJfFfnXgo5CZsO28xldrtX",
    "client_secret":"gag15E233GAH7YZfyS8tkdMeK7lZOz2oTWUUebIQveW1TuX6yFHsFlR6E_s3zCyV",
    "grant_type":"refresh_token",
    "refresh_token":localStorage.getItem('refresh_token')
  };


  const query = {
    method:'post',
    url:'https://backoffice-moa.eu.auth0.com/oauth/token',
    headers:{
      'content-type': 'application/json'
    },
    data:body
  }

  return new Promise((resolve, reject) => {
    const status = error.status;
    if (status === 401 || status === 403) {
      console.log('-- refreshing token')
      axios(query)
      .then(response => {
        localStorage.setItem('token', response.data.id_token);
        localStorage.setItem('refresh_token', response.data.refresh_token);
        resolve(response.status)
      })
      .catch(e => {
        
        reject("ra.auth.sign_in_error")
      })  

    } else {
      resolve()
    }


  })


}

const auth0Provider = {
  login,
  logout: params =>{
    localStorage.removeItem('token')
    localStorage.removeItem('permissions')
    return Promise.resolve()
  },
  checkAuth: params =>{
    if(localStorage.getItem('token')){
      return Promise.resolve()
    }else {
      return Promise.reject()
    }
    
  },
  checkError,
  getPermissions: () => {
    const permissions = localStorage.getItem('permissions')
    if (!permissions)
      return Promise.reject();
    const roles = permissions.split(',');
    return roles ? Promise.resolve(roles) : Promise.reject();
  },
};

export default auth0Provider