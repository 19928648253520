import React from 'react';
import { SimpleForm, TextInput, SelectInput, Create, ReferenceInput, AutocompleteInput, NumberInput, DateTimeInput } from 'react-admin';
import { flagFilter, statusOptions } from '../../const/const';
import {validatePositiveNumber} from '../../../utils/validation'

const CoreObjectCreate = props => {
  return (
    <Create {...props} >
      <SimpleForm>
        <ReferenceInput source="coonotionid" reference="conotions" label="coonotionid" filterToQuery={searchText => ({ noname: searchText })}>
          <AutocompleteInput optionText="noname" />
        </ReferenceInput>
        <TextInput source="cootitle" label="cootitle"/>
        <NumberInput source="cootypeid" label="cootypeid" validate={validatePositiveNumber}/>
        <SelectInput source="coostatusid" choices={statusOptions} optionValue="_id" label="coostatusid"/>
        <NumberInput source="coodoid" label="coodoid" validate={validatePositiveNumber}/>
        <NumberInput source="cootagid" label="cootagid" validate={validatePositiveNumber}/>
        <ReferenceInput source="cootagid" reference="tags" label="cootagid" filterToQuery={searchText => ({ taname: searchText })}>
          <AutocompleteInput optionText="taname" />
        </ReferenceInput>
        <SelectInput source="cooflag" choices={flagFilter} optionValue="_id" label="cooflag"/>
        <DateTimeInput showTime source="cooupdatedate" label="cooupdatedate"/>
        <DateTimeInput showTime source="coocreationdate" label="coocreationdate"/>
      </SimpleForm>
    </Create>
  );
}

export default CoreObjectCreate;
