import React from 'react';
import {
  List, Datagrid, TextField, Filter, NumberInput, ReferenceArrayField, SingleFieldList, ChipField, ReferenceInput, AutocompleteInput,
  ReferenceField, Pagination,
} from 'react-admin';
import { paginationRow } from '../../const/const';

const CoTagMapFilters = (props) => (
  <Filter {...props}>
      <NumberInput label="Search by tagmapId" source="id"  />
      <ReferenceInput source="id" reference="tags" label="Search by tagmap" filterToQuery={searchText => ({ taname: searchText })}>
        <AutocompleteInput optionText="taname"/>
      </ReferenceInput>
  </Filter>
);

const NewPagination = props => <Pagination rowsPerPageOptions={paginationRow} {...props} />;

const CoTagMapsList = props => (
  <List filters={<CoTagMapFilters />} {...props}  bulkActionButtons={false} pagination={<NewPagination />}>
      <Datagrid rowClick="edit">
          <TextField source="id" label="TagmapId"/>
          <ReferenceField label="name" source="id" reference="tags">
              <TextField source="taname" />
          </ReferenceField>
          <ReferenceArrayField label="tamtacopy" source="tamtacopy" reference="tags">
            <SingleFieldList >
              <ChipField source="taname"  />
            </SingleFieldList>
          </ReferenceArrayField>
      </Datagrid>
  </List>
);




export default CoTagMapsList;
