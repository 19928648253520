// in src/App.js
import React from "react";
import { Admin, Resource } from "react-admin";
import polyglotI18nProvider from "ra-i18n-polyglot";
import englishMessages from "ra-language-english";

import authProvider from "./provider/auth0";

import clusters from "./resources/cluster";
import tags from "./resources/tags";
import usertags from "./resources/userTags";
import tagGrants from "./resources/tagGrant";
import users from "./resources/users";
import descriptions from "./resources/descriptions";
import tagsmaponetomany from "./resources/tagsmaponetomany";
import tagsmapmanytoone from "./resources/tagsmapmanytoone";
import tagsmaponetoone from "./resources/tagsmaponetoone";
import backofficeUsers from "./resources/backofficeUsers";
import backofficeRoles from "./resources/backofficeRoles";
import customLayout from "./resources/CustomLayout/customLayout";
import taglabels from "./resources/taglabel";
import tagcluster from "./resources/tagcluster";
import logs from "./resources/logs";
import cotypes from "./resources/cotypes";
import conotions from "./resources/conotions";
import codusertype from "./resources/codusertypes";
import codescritiontypes from "./resources/codescriptiontypes";
import coserverconfigs from "./resources/coserverconfigs";
import coserverconfigtypes from "./resources/coserverconfigtypes";
import documents from "./resources/documents";
import learningBadges from "./resources/learningBadges";
import learningBadgeDocuments from "./resources/learningBadgeDocuments";
import learningBadgeRanges from "./resources/learningBadgeRanges";
import learningBadgeTags from "./resources/learningBadgeTags";
import uploads from "./resources/uploads";
import coreobjects from "./resources/coreobjects";
import customRoutes from "./resources/CustomLayout/customRoutes";
import instagramLogin from "./resources/instagram-login";

// import configs from './resources/configs';

import getTheme from "./resources/CustomLayout/Theme/myTheme";
import Dashboard from "./resources/CustomLayout/Components/Dashboard";
import dataProvider from "./provider/data-provider";

console.log(process.env.REACT_APP_PROXY_SERVER_URL);

const messages = {
  en: englishMessages,
};

const i18nProvider = polyglotI18nProvider((locale) => messages[locale], "en", {
  allowMissing: true,
});

const resources = [
  tags,
  clusters,
  users,
  descriptions,
  tagsmaponetomany,
  tagsmapmanytoone,
  tagsmaponetoone,
  taglabels,
  usertags,
  logs,
  coserverconfigs,
  conotions,
  cotypes,
  codusertype,
  codescritiontypes,
  tagGrants,
  tagcluster,
  documents,
  learningBadges,
  learningBadgeDocuments,
  learningBadgeRanges,
  learningBadgeTags,
  coserverconfigtypes,
  uploads,
  coreobjects,
  instagramLogin,
];
const adminResources = [backofficeUsers, backofficeRoles];

// const MyLoginPage = () => <Login backgroundImage="https://southpigalle.io/wp-content/uploads/2020/09/HOME-TEST-1024x539.jpg" />;

const App = () => (
  <Admin
    customRoutes={customRoutes}
    theme={getTheme()}
    layout={customLayout}
    authProvider={authProvider}
    dataProvider={dataProvider}
    dashboard={Dashboard}
    locale="en"
    i18nProvider={i18nProvider}
    // loginPage={MyLoginPage}
  >
    {resources.map((r) => (
      <Resource key={r.name} {...r} />
    ))}
    {adminResources.map((r) => (
      <Resource key={r.name} {...r} />
    ))}
  </Admin>
);

export default App;
