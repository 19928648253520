import React from 'react';
import { Layout } from 'react-admin';
import CustomAppBar from './AppBar/view';
import CustomMenu from './Menu/view';

const CustomLayout = props => <Layout
    {...props}
    appBar={CustomAppBar}
    // sidebar={MySidebar}
    menu={CustomMenu}
    // notification={MyNotification}
/>;

export default CustomLayout;