import React from 'react';
import {
  Create, SimpleForm, AutocompleteInput, ReferenceArrayInput, AutocompleteArrayInput,
  ReferenceInput, required,
} from 'react-admin';


const TagsmapOneToManyCreate = props => (
  <Create {...props}>
      <SimpleForm>
        <ReferenceInput label="tag name" source="id" reference="tags" filterToQuery={searchText => ({ "taname": searchText })} validate={[required()]} >
          <AutocompleteInput optionText="taname" />
        </ ReferenceInput>
        <ReferenceArrayInput label="tamtacopy" source="tamtacopy" reference="tags" filterToQuery={searchText => ({ "taname": searchText })}>
          <AutocompleteArrayInput optionText="taname"  />
        </ReferenceArrayInput>
      </SimpleForm>
  </Create>
);

export default TagsmapOneToManyCreate;
