import React, { useState } from "react";
import FacebookLogin from "react-facebook-login";
import { useMutation } from "@apollo/client";
import { changeConfigToken } from "../../../utils/graph/facebook";

const InstagramLogin = () => {
  const [accessToken, setAccessToken] = useState(null);
  const [instagramLoginAndProcessStatus, setInstagramLoginAndProcessStatus] =
    useState("");

  const [changeConfigFb] = useMutation(changeConfigToken, {
    variables: {
      facebookToken: accessToken,
    },
  });

  const responseFacebook = (response) => {
    if (
      response &&
      !response.error &&
      response.accessToken &&
      response.accessToken !== ""
    ) {
      setAccessToken(response.accessToken);
      changeConfigFb();
      setInstagramLoginAndProcessStatus("Instagram configuration set.");
    } else {
      let strError = "Error in instagram configuration changing process.";
      if (response && response.error && response.error.message) {
        strError += response.error.message;
      }
      setInstagramLoginAndProcessStatus(strError);
    }
  };

  const env = localStorage.getItem("env-name");
  const appIds = {
    "local-dev": "714564475414114",
    "shiseido-dev": "714564475414114",
    "shiseido-prod": "445540529666004",
    "shiseido-preprod": "445540529666004",
    "vca-dev": "714564475414114",
    "vca-prod": "1383103398538261",
  };

  return (
    <div style={{ padding: "2rem 0" }}>
      {appIds && appIds[env] && appIds[env] !== "" && (
        <FacebookLogin
          appId={appIds[env]}
          scope="pages_show_list,instagram_basic,instagram_manage_insights"
          callback={responseFacebook}
        />
      )}
      <br />
      {instagramLoginAndProcessStatus}
    </div>
  );
};

export default InstagramLogin;
