import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { Title, useNotify, usePermissions } from 'react-admin';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { useForm } from "react-hook-form";
import cleanCacheRequest from '../../../utils/cleanCache'
import callReload from '../../../utils/callReload'
import CallAdd from '../../../utils/callAdd'
import './styles.css'
import Nlp from './Nlp'

export default () => {
  const {permissions} = usePermissions();
  console.log('permissions')
  console.log(permissions)
  const { register, handleSubmit } = useForm();
  const { register: registerCache, handleSubmit: handleCache, errors } = useForm();
  const { register: registerEntities, handleSubmit: handleEntities, errors: errorsEntities } = useForm();
  const { register: registerValues, handleSubmit: handleValues, errors: errorsValues } = useForm();
  const { register: registerSynonym, handleSubmit: handleSynonym, errors: errorsSynonym } = useForm();
  const notify = useNotify();
  const onSubmit = ({redisKey}) => {  
    if (!redisKey) return notify('You need to provide a key')
    cleanCacheRequest(redisKey, notify);
  };
  const reloadCache = (corpusId) =>{
    callReload(Number(corpusId.corpusId))
  }
  const addEntities = (entity) => {
    const value = []
    let split = entity.value.split(',')
    split.forEach((word) => {value.push(word.trim())})
    let toSend = {name: entity.name, value: value}
    CallAdd(toSend, Number(entity.corpusId), "entity")
  }
  const addValues = (value) => {
    const syn = []
    let split = value.value.split(',')
    split.forEach((word) => {syn.push(word.trim())})
    let toSend = {parent_value: value.parent_value, name: value.name, value: syn}
    CallAdd(toSend, Number(value.corpusId), "rv")
  }
  const addSynonyms = ((value) => {
    const syn = [];
    let split = value.value.split(',')
    split.forEach((word) => {syn.push(word.trim())})
    let toSend = {name: value.name, value: syn}
    CallAdd(toSend, Number(value.corpusId), "synonym")
  })
  if (permissions && !permissions.find((role) => (role === 'superAdmin')))
  {
    return (
    <Card style={{marginTop: 50}}>
      <CardContent>
        Vous devez être administrateur pour voir cette page<p/>
      </CardContent>
    </Card>
  )}
  return (
    <>
  <Card style={{marginTop: 50}}>
    <Title title="Welcome to the administration" />
    <CardContent>
      Pour supprimer une key ou un ensemble de keys dans le cache veuillez indiquer le préfixe de la clef:<p/>
      Le préfixe de la key (DEV_shiseido) par exemple, est déterminé automatiquement en fonction de l'environnement<p/>
      Documents : _doid_<p/>
      Document Tags: _flow_docs ( pas d'id )<p/>
      Objects Core: _coid_<p/>
      Tags: _taid_<p/>
      Users : _usid_<p/>
      User Connections Status: _us_lastConnectionDate_<p/>
      User Descriptions : _deid_lang_<p/>
      User Tags : _user_id_tags (remplacer id par le usid)<p/>
    </CardContent>
    <form style={{marginBottom: 50, marginLeft: 50}} onSubmit={handleSubmit(onSubmit)}>
      <span>{localStorage.getItem('env-name')}</span>
      <TextField name="redisKey" label="Redis Key" variant="outlined" style={{margin: 10}} inputRef={register}/>
      <Button variant="outlined" color="primary" style={{margin: 10, height: 60}} type="submit">
        Clean Cache
      </Button>
    </form>
  </Card>
  {
  (localStorage.getItem('env-name') === 'local-dev' ||
    localStorage.getItem('env-name') === 'dior-vm-dev' ||
    localStorage.getItem('env-name') === 'vca-dev') && 
      (
        <Card>
        <CardContent class="cardnlp">
          <h2>NLP Management</h2>
          <div class="main">
            <section id="sendNlp">
              <Nlp></Nlp>
            </section>
            <section id="cache">
              <h3>Reload nlp cache</h3>
              <form class= "formCache" onSubmit={handleCache(reloadCache)}>
                <input type="text" placeholder="CorpusId" name="corpusId" ref={registerCache({required: true, pattern: /^[0-9]+$/})}/>
                {errors.corpusId && notify('Corpusid must be number type')}
                <Button variant="outlined" color="primary" style={{margin: 10 , height: 40}} type="submit">
                  Reload Cache
                </Button>
              </form>
            </section>
            <section id="addDb">
              <h3>Add entities, resolved value, synonyms</h3>
              <div class="add">
                <form onSubmit={handleEntities(addEntities)}>
                  <div class="input">
                    <input type="text" placeholder="CorpusId" name="corpusId" ref={registerEntities({required: true, pattern: /^[0-9]+$/})}/>
                    {errorsEntities.corpusId && notify('Corpusid must be number type')}
                    <input type="text" placeholder="name entity" name="name" ref={registerEntities({required: true})}/>
                    {errorsEntities.entityname && notify('Entity name required')}
                    <input type="text" placeholder="value,value,value" name="value" ref={registerEntities({required: true})}/>
                    {errorsEntities.resolvedvalue && notify('resolvedvalue required')}
                  </div>
                  <input type="submit" value="add Entity"/>
                </form>
                <form onSubmit={handleValues(addValues)}>
                  <div class="input">
                    <input type="text" placeholder="CorpusId" name="corpusId" ref={registerValues({required: true, pattern: /^[0-9]+$/})}/>
                    {errorsValues.corpusId && notify('Corpusid must be number type')}
                    <input type="text" placeholder="name entity parent" name="parent_value" ref={registerValues({required: true})}/>
                    {errorsValues.parent_value && notify('Entity name required')}
                    <input type="text" placeholder="name value" name="name" ref={registerValues({required: true})}/>
                    {errorsValues.name && notify('Resolved value name required')}
                    <input type="text" placeholder="synonym,synonym" name="value" ref={registerValues({required: true})}/>
                    {errorsValues.value && notify('Synonym required')}
                  </div>
                  <input type="submit" value="add ResolvedValue"/>
                </form>
                <form onSubmit={handleSynonym(addSynonyms)}>
                  <div class="input">
                    <input type="text" placeholder="CorpusId" name="corpusId" ref={registerSynonym({required: true, pattern: /^[0-9]+$/})}/>
                    {errorsSynonym.corpusId && notify('Corpusid must be number type')}
                    <input type="text" placeholder="name value" name="name" ref={registerSynonym({required: true})}/>
                    {errorsSynonym.name && notify('Parent value required')}
                    <input type="text" placeholder="synonym,synonym" name="value" ref={registerSynonym({required: true})}/>
                    {errorsSynonym.value && notify('Synonym required')}
                  </div>
                  <input type="submit" value="add Synonym"/>
                </form>
              </div>
            </section>
          </div>
        </CardContent>
      </Card>
      )
    }
  <Card>
    
  </Card>
  </>
)};