import React from 'react';
import {Create, SimpleForm, AutocompleteInput, ReferenceInput, NumberInput, required} from 'react-admin';

const TagsmapOneToOneCreate = props => (
  <Create {...props}>
    <SimpleForm>
      <ReferenceInput
        label="tag 1"
        source="tamatid"
        reference="tags"
        filterToQuery={searchText => ({"taname": searchText})}
        validate={[required()]}>
        <AutocompleteInput optionText="taname"/>
      </ ReferenceInput>
      <ReferenceInput
        label="tag 2"
        source="tamtacopy"
        reference="tags"
        filterToQuery={searchText => ({"taname": searchText})}
        validate={[required()]}>
        <AutocompleteInput optionText="taname"/>
      </ ReferenceInput>
      <NumberInput initialValue={1} source='tamflag' label='flag'/>
    </SimpleForm>
  </Create>
);

export default TagsmapOneToOneCreate;
