import * as React from "react";
import { SaveButton, Toolbar } from "react-admin";
import DeleteButtonWithConfirmation from "./DeleteButtonWithConfirmation";

const CustomToolbar = (props) => (
  <Toolbar {...props}>
    <SaveButton />
    <DeleteButtonWithConfirmation />
  </Toolbar>
);

export default CustomToolbar;
