import React from 'react';
import { Datagrid, Filter, TextField, TextInput, Pagination, List } from 'react-admin';
import { paginationRow } from '../../const/const'

const UserFilters = (props) => (
  <Filter {...props}>
      <TextInput label="Search by name" source="name"  />
  </Filter>
);

const NewPagination = props => <Pagination rowsPerPageOptions={paginationRow} {...props} />;

const BackofficeUsersList = props => (
<List filters={<UserFilters />} {...props}  bulkActionButtons={false} pagination={<NewPagination />}>
      <Datagrid rowClick="edit">
          <TextField source="id" label="id" />
          <TextField source="name" label="name" />
      </Datagrid>
  </List>
);




export default BackofficeUsersList;
