import React from 'react';
import { List, Datagrid, TextField, NumberField, ReferenceField, NumberInput, Filter, SelectInput, DateField, Pagination, SelectField, ReferenceInput, AutocompleteInput, TextInput, EditButton } from 'react-admin';
import { flagFilter, paginationRow, statusOptions } from '../../const/const'
import CustomNumberFieldRedirectToDoc from '../../CustomLayout/Components/CustomNumberFieldRedirectToDoc'
import DeleteButton from '../../CustomLayout/Components/DeleteButtonWithConfirmation';

const CoreObjectFilters = (props) => (
  <Filter {...props}>
      <NumberInput source="id" label="Id"/>
      <ReferenceInput source="coonotionid" reference="conotions" label="coonotionid" filterToQuery={searchText => ({ noname: searchText })}>
        <AutocompleteInput optionText="noname" />
      </ReferenceInput>
      <TextInput source="cootitle" label="cootitle"/>
      <NumberInput source="cootypeid" label="cootypeid"/>
      <SelectInput source="coostatusid" choices={statusOptions} optionValue="_id" label="coostatusid"/>
      <NumberInput source="coodoid" label="coodoid"/>
      <NumberInput source="cootagid" label="cootagid"/>
      <ReferenceInput source="cootagid" reference="tags" label="cootag name" filterToQuery={searchText => ({ taname: searchText })}>
        <AutocompleteInput optionText="taname" />
      </ReferenceInput>
      <SelectInput source="cooflag" choices={flagFilter} optionValue="_id" label="cooflag"/>
  </Filter> 
);

const NewPagination = props => <Pagination rowsPerPageOptions={paginationRow} {...props} />;

const CoreObjectList = props => (
  <List filters={<CoreObjectFilters />} bulkActionButtons={false} {...props} pagination={<NewPagination />}>
    <Datagrid>
      <NumberField source="id" label="Id"/>
      <ReferenceField source="coonotionid" reference="conotions" label="coonotionid">
        <TextField source="noname" label="name" />
      </ReferenceField>
      <TextField source="cootitle" label="cootitle"/>
      <NumberField source="cootypeid" label="cootypeid"/>
      <SelectField source="coostatusid" choices={statusOptions} optionValue="_id" label="coostatusid"/>
      <CustomNumberFieldRedirectToDoc source="coodoid" label="DocId" />
      <NumberField source="cootagid" label="cootagid"/>
      <ReferenceField source="cootagid" reference="tags" label="cootagid">
        <TextField source="taname" label="name" />
      </ReferenceField>
      <NumberField source="cooflag" label="cooflag"/>
      <DateField showTime source="cooupdatedate" label="cooupdatedate"/>
      <DateField showTime source="coocreationdate" label="coocreationdate"/>
      <EditButton />
      <DeleteButton />
    </Datagrid>
  </List>
);

export default CoreObjectList;
