import React from 'react';
import { SimpleForm, TextInput, SelectInput, BooleanInput, Create, NumberInput } from 'react-admin';

import { typeOptions, langueOption, statusOptions, flagFilter } from '../../const/const';

const UserCreate = props => (
  <Create {...props}>
      <SimpleForm>
          <TextInput source="uspuid" label="puid"/>
          <SelectInput initialValue={1} source="ustype" choices={typeOptions} optionValue="_id" />
          <SelectInput initialValue={3} source="usstatusid" choices={statusOptions} optionValue="_id" />
          <BooleanInput initialValue={false} source="usanonymous" label="Anonynous"/>
          <BooleanInput initialValue={true} source="usruledisplay" label="ruleDisplay"/>
          <TextInput source="usupdatesource" label="updateSource"/>
          <SelectInput initialValue={2} source="uslanguage" choices={langueOption} optionValue="_id" label="Language"/>
          <SelectInput initialValue={1} source="usflag" choices={flagFilter} optionValue="_id" label='flag'/>
          <NumberInput initialValue={1} source="ustotallogins" label="totallogins"/>
          <NumberInput initialValue={0} source="usloginerrors" label="loginerrors"/>
      </SimpleForm>
  </Create>
);

export default UserCreate;
