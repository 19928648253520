import React from 'react';
import {  Create, SimpleForm, NumberInput, TextInput, DateInput, ReferenceInput, AutocompleteInput, SelectInput } from 'react-admin';
import { statusOptions, flagFilter } from '../../const/const';
import {validatePositiveNumber} from '../../../utils/validation'

const LearningBadgeCreate = props => {
  return (
    <Create {...props} >
      <SimpleForm>
        <TextInput source="lbtitle" label="Titre" />
        <NumberInput source="lbdoid" label="id" validate={validatePositiveNumber}/>
        <NumberInput source="lbtype" label="Type" validate={validatePositiveNumber}/>
        <SelectInput source="lbstatusid" choices={statusOptions} optionValue="_id" label="Status"/>
        <SelectInput source="lbflag" choices={flagFilter} optionValue="_id" label='flag'/>
        <TextInput source="lbpic" label="Pic" type="url"/>
        <NumberInput source="lborder" label="lborder" validate={validatePositiveNumber}/>
        <ReferenceInput source="lbparentid" reference="learningbadges" label="Parent" filterToQuery={searchText => ({ lbtitle: searchText })}>
          <AutocompleteInput optionText="lbtitle"/>
        </ReferenceInput>
        <NumberInput source="lbparentid" label="ParentId" validate={validatePositiveNumber}/>
        <DateInput source="lbcreationdate" label="Creation Date" />
        <DateInput source="lbupdatedate" label="Update Date" />
      </SimpleForm>
    </Create>
  );
}

export default LearningBadgeCreate;
