import LearningBadgeTagList from './views/LearningBadgeTagList'
import LearningBadgeTagEdit from './views/LearningBadgeTagEdit'
import LearningBadgeTagCreate from './views/LearningBadgeTagCreate'

export default {
  name:"learningbadgetags",
  list:LearningBadgeTagList,
  edit:LearningBadgeTagEdit,
  create:LearningBadgeTagCreate,
}
