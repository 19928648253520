import ClusterList from './views/clusterList'
import ClusterEdit from './views/clusterEdit'
import ClusterCreate from './views/clusterCreate'

export default {
  name:"clusters",
  list:ClusterList,
  edit:ClusterEdit,
  create:ClusterCreate
}


