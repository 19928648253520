import React, { useState, useRef } from 'react';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { useMutation } from '@apollo/client';

import { addLessonMutation, addQuizMutation } from '../../../utils/graph/badges';

import './styles.css';

const getDocName = (versions) => {
    let title = null;
    versions.forEach((version) => {
        if (version.lang === "en") {
            title = version.title;
            return;
        }
    })
    if (!title) {
        title = versions[0].title;
    }
    return title;
}

const AddDocument = ({ badgeId, refetchBadges, selectDocs }) => {
    const [docMenuOpen, setDocMenuOpen] = useState(false);
    const [docId, setDocId] = useState(null);
    const [docName, setDocName] = useState("");

    const docElRef = useRef(null);

    const [addLesson] = useMutation(addLessonMutation, {
        variables: {
            docId: Number(docId),
            badgeId: Number(badgeId),
        }
    });
    const [addQuiz]= useMutation(addQuizMutation, {
        variables: {
            docId: Number(docId),
            badgeId: Number(badgeId),
        }
    });

    const handleDocClick = () => {
        setDocMenuOpen(!docMenuOpen);
    }

    const handleDocItemClick = (docId, versions) => {
        setDocMenuOpen(false);
        setDocId(docId);
        versions && setDocName(getDocName(versions));
    }

    const handleDocClickAway = () => {
        setDocMenuOpen(false);
    }

    const handleAddLesson = () => {
        if (docId && badgeId) {
            addLesson();
            if (refetchBadges) {
                refetchBadges();
            }
            setDocId(null);
            setDocName("");
        }
    }

    const handleAddQuiz = () => {
        if (docId && badgeId) {
            addQuiz();
            if (refetchBadges) {
                refetchBadges();
            }
            setDocId(null);
            setDocName("");
        }
    }

    return (
      <CardContent className="addDocument">
        <ClickAwayListener onClickAway={handleDocClickAway}>
          <Button classes={{ label: "Mui_button" }} onClick={handleDocClick}>
            Select Doc
          </Button>
        </ClickAwayListener>
        <h5 className="filters_text" ref={docElRef}>
          {docName}
        </h5>
        <Menu
          anchorEl={docElRef.current}
          open={docMenuOpen}
          PaperProps={{
            style: {
              width: "400px",
            },
          }}
        >
          <MenuItem onClick={() => handleDocItemClick(null)}>None</MenuItem>
          {selectDocs &&
            selectDocs.admin.documents.map((doc) => {
              return (
                <MenuItem
                  key={Math.random()}
                  onClick={() => handleDocItemClick(doc.docId, doc.versions)}
                  style={{ overflow: "visible" }}
                >
                  {doc.versions ? (
                    <h6>{getDocName(doc.versions)}</h6>
                  ) : (
                    doc.docId
                  )}
                </MenuItem>
              );
            })}
        </Menu>
        <Button classes={{ label: "Mui_button" }} onClick={handleAddLesson}>
          Add as Lesson
        </Button>
        <Button classes={{ label: "Mui_button" }} onClick={handleAddQuiz}>
          Add as Quiz
        </Button>
      </CardContent>
    );
}

export default AddDocument;