import React from 'react';
import { SelectInput, Datagrid, TextField, TextInput, NumberInput, Filter, NumberField, DateField, SelectField, Pagination, List } from 'react-admin';
import { descriptionType, flagFilter, paginationRow } from '../../const/const'

const DescriptionFilters = (props) => (
  <Filter {...props}>
      <NumberInput label="Search by id" source="id"  />
      <TextInput label="Search by delink" source="delink"  />
      <SelectInput source="detypeid" choices={descriptionType} optionValue="_id" label="Search by Type"/>
      <SelectInput source="deflag" choices={flagFilter} optionValue="_id" label="Search by flag"/>
      <NumberInput label="Search by usid" source="deusid"  />
  </Filter>
);

const NewPagination = props => <Pagination rowsPerPageOptions={paginationRow} {...props} />;

const DescriptionsList = props => (
  <List filters={<DescriptionFilters/>} {...props}  bulkActionButtons={false} pagination={<NewPagination />}>
      <Datagrid rowClick="edit">
          <NumberField source="id" label="id"/>
          <TextField source="delink" label="delink"/>
          <SelectField source="detypeid" choices={descriptionType} optionValue="_id" label="Type"/>
          <NumberField source="deflag" label="flag" />
          <NumberField source="devalue" label="value"/>
          <NumberField source="deusid" label="deusid"/>
          <DateField showTime source="decreationdate" label="creationDate" />
          <DateField showTime source="deupdateDate" label="updatedate" />
      </Datagrid>
  </List>
);




export default DescriptionsList;
