import React from 'react';
import { Datagrid, TextField, Filter, NumberField, SelectInput, Pagination, DateField, SelectField, TextInput, NumberInput, EditButton, List } from 'react-admin';
import CustomNumberFieldRedirectToDoc from '../../CustomLayout/Components/CustomNumberFieldRedirectToDoc';
import { flagFilter, paginationRow, documentTypes, languages } from '../../const/const';
import DeleteButton from '../../CustomLayout/Components/DeleteButtonWithConfirmation';

const DocumentFilters = (props) => (
  <Filter {...props}>
      <SelectInput source="doflag" choices={flagFilter} optionValue="_id" label="Search by flag"/>
      <TextInput source="dotitle" label="Search by title"/>
      <NumberInput source="doid" label="Search by id"/>
      <NumberInput source="doclonedfromid" label="Search by cloneid"/>
      <NumberInput source="doparentid" label="Search by parentid"/>
      <SelectInput source="dolanguage" choices={languages} optionValue="_id" label="Search by Language"/>
      <SelectInput source="dotype" choices={documentTypes} optionValue="_id" label="Search by type"/>
  </Filter>
);

const NewPagination = props => <Pagination rowsPerPageOptions={paginationRow} {...props} />;

const DocumentsList = props => {
  return (
    <List {...props} filters={<DocumentFilters/>} bulkActionButtons={false} pagination={<NewPagination />} sort={{ field: 'doid', order: 'asc' }}>
        <Datagrid>
            <NumberField source="doid" label="doid" />
            <CustomNumberFieldRedirectToDoc source="doparentid" label="Parent" />
            <SelectField source="dotype" choices={documentTypes} optionValue="_id" label="Type"/>
            <TextField source="dotitle" label="Titre" />
            <DateField showTime source="doupdatedate" label="updatedate" />
            <NumberField source="doflag" label="Flag" />
            <SelectField source="dolanguage" choices={languages} optionValue="_id" label="Language"/>
            <CustomNumberFieldRedirectToDoc source="doclonedfromid" label="Clone From Id" />
            <EditButton />
            <DeleteButton />
        </Datagrid>
    </List>
  );
}

export default DocumentsList;
