import React from 'react';
import { SimpleForm, ReferenceInput, AutocompleteInput, Create, NumberInput, SelectInput } from 'react-admin';
import { flagFilter } from '../../const/const';

const defaultValue = {
  tgtagid: 0,
  tggranttagid: 0,
  tgflag: 1,
}

const TagGrantCreate = props => {
  return (
  <Create {...props}>
      <SimpleForm defaultValue={defaultValue}>
        <NumberInput source="tgtagid" label="tgtagid" />
        <ReferenceInput source="tggranttagid" reference="tags" label="Tag" filterToQuery={searchText => ({ taname: searchText })}>
          <AutocompleteInput optionText="taname" />
        </ReferenceInput>
        <SelectInput source="tgflag" choices={flagFilter} optionValue="_id" label='flag'/>
      </SimpleForm>
  </Create>
)};

export default TagGrantCreate;
