import React from 'react';
import { SimpleForm, TextInput, SelectInput, NumberInput, Create, required, ReferenceInput, AutocompleteInput} from 'react-admin';
import { parse } from "query-string";
import { langueOption, flagFilter } from '../../const/const';

const TagLabelCreate = props => {
  const { tltaid: tltaid_string } = parse(props.location.search);
  const tltaid = tltaid_string ? parseInt(tltaid_string, 10) : "";
  return (
    <Create {...props} >
        <SimpleForm defaultValue={{tltaid}} redirect={tltaid ? `/tags/${tltaid}/1` : '/taglabels'} >
            <NumberInput source="tltaid" label="tagId"/>
            <ReferenceInput source="tltaid" reference="tags" label="tag" filterToQuery={searchText => ({ "taname": searchText })}>
              <AutocompleteInput optionText="taname" />
            </ReferenceInput>
            <SelectInput initialValue={2} source="tllanguage" choices={langueOption} optionText="name" label="language" optionValue="_id" validate={[required()]}/>
            <TextInput source="tllabel" label="label" validate={[required()]}/>
            <SelectInput initialValue={1} source="tlflag" choices={flagFilter} optionValue="_id" label='flag'/>
        </SimpleForm>
    </Create>
  );
}

export default TagLabelCreate;
