import React from 'react';
import CardContent from '@material-ui/core/CardContent';
import { useQuery } from '@apollo/client';
import { useNotify } from 'react-admin';

import { badgeQuery } from '../../../utils/graph/badges';
import { docQuery } from '../../../utils/graph/documents';

import Badge from './Badge';

const BadgeList = ({ marketId, brandId}) => {

    const notify = useNotify();
    const tags = [marketId, brandId].filter(tag => tag);
    const intersectTags = [marketId, brandId].map(tagIds => ({tagIds}))

    const useQueryMultiple = () => {
        const res1 = useQuery(docQuery, {
            variables: {
                intersectTags: intersectTags,
            },
        });
        const res2 = useQuery(badgeQuery, {
            variables: {
                tags: tags,
            },
        });
        return [res1, res2];
      }

    const [selectDocs, badges] = useQueryMultiple();

    return (
        <div>
            <CardContent className="badgeManagement_topBar">
                    <h2>Badge Title</h2>
                    <h2>Badge Image</h2>
                    <h2>Badge Range</h2>
                    <h2>DegreeRef ID</h2>
                    <h2>DegreeRef Title</h2>
                    <h2>Documents</h2>
            </CardContent>
            {(badges.loading || selectDocs.loading) && <h2>Loading...</h2>}
            {(badges.data && selectDocs.data) &&
                badges.data.admin.badges.map((badge) => {
                return (
                    <Badge
                        key={badge.badgeId + badge.badgeTitle}
                        badgeId={badge.badgeId}
                        badgeTitle={badge.title}
                        pic={badge.pic}
                        score={badge.ranges ? parseInt(badge.ranges[0].score) : 0}
                        rangeId={badge.ranges ? parseInt(badge.ranges[0].rangeId) : 0}
                        degreeRefId={badge.degreeRef ? badge.degreeRef.docId : ""}
                        degreeRefTitle={badge.degreeRef ? badge.degreeRef.title : ""}
                        docs={badge.documents}
                        links={badge.badgeDocuments}
                        tags={tags}
                        refetchBadges={badges.refetch}
                        getBadgeLoading={badges.loading}
                        selectDocs={selectDocs.data}
                    />
                )
            })}
            {badges.error &&
                notify(badges.error.message)
            }
            {selectDocs.error &&
                notify(selectDocs.error.message)
            }
        </div>
    )
};

export default BadgeList;