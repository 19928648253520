import axios from 'axios';


export default async (corpusid) => {
    try {
        const response = await axios({
            method: 'POST',
            url: `${process.env.REACT_APP_PROXY_SERVER_URL}nlp/Batch`,
            headers:{
                env: localStorage.getItem('env-name'),
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                Authorization: localStorage.getItem('token'),
            },
            data: {
                "XP_SYSTEM": [
                    {
                        "laid": 2,
                        "corpusid": corpusid,
                        "mode": "reload"
                    }
                ]
            }
        })
        alert(`Cache reloaded : ${response}`);
    } catch (e) {
        alert(e)
    }
}


