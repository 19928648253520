import React from 'react';
import { List, Datagrid, TextField, Filter, TextInput, NumberField, NumberInput, SelectInput, SelectField, Pagination } from 'react-admin';
import { flagFilter, paginationRow } from '../../const/const'

const NewPagination = props => <Pagination rowsPerPageOptions={paginationRow} {...props} />;

const CotypeFilters = (props) => (
  <Filter {...props}>
      <NumberInput label="Search by id" source="id"  />
      <TextInput label="Search by name" source="cotname"  />
      <SelectInput source="cotflag" choices={flagFilter} optionValue="_id" label="Search by flag"/>
      <NumberInput label="Search by conotion id" source="cotnotionid"  />
  </Filter>
);

const CotypesList = props => (
  <List filters={<CotypeFilters />} {...props}  bulkActionButtons={false} pagination={<NewPagination />}>
      <Datagrid rowClick="edit">
          <NumberField source="id" label="Id"/>
          <TextField source="cotname" label="Cotype name" />
          <SelectField source="cotflag" choices={flagFilter} optionValue="_id" />
          <NumberField source="cotnotionid" label="cotnotionid" />
      </Datagrid>
  </List>
);




export default CotypesList;
