import React from 'react';
import {
  List, Datagrid, NumberField, TextField, Filter, TextInput, SelectInput, BooleanField,
  ReferenceField, Pagination,
} from 'react-admin';
import {flagFilter, paginationRow} from '../../const/const'

const CodescriptiontypeFilters = (props) => (
  <Filter {...props}>
      <TextInput label="Search by name" source="noname"  />
      <SelectInput choices={flagFilter} source="codtflag" label="Search by flag" />
  </Filter>
);

const NewPagination = props => <Pagination rowsPerPageOptions={paginationRow} {...props} />;

const CodescriptiontypeList = props => (
  <List filters={<CodescriptiontypeFilters />} {...props}  bulkActionButtons={false} pagination={<NewPagination />}>
      <Datagrid rowClick="edit">
        <NumberField source="id" label="Id"/>
        <ReferenceField source="codtnotionid" reference="conotions" label="Notion name">
          <TextField source="noname" label="name" />
        </ReferenceField>
        <NumberField source="codtformatid" label="Format Id"/>
        <TextField source="codtname" label="Codtname" />
        <TextField source="codtfieldname" label="Codtfieldname" />
        <TextField source="codtfieldparam" label="Codtfieldparam" />
        <BooleanField source="codtmultiple" label="Codtmultiple"/>
        <BooleanField source="codtargscreate" label="Codtargscreate"/>
        <NumberField source="codtflag" label="Flag"/>
        <TextField source="codtdescription" label="Codtdescription" />
        <BooleanField source="codtdoaudit" label="Codtdoaudit"/>
      </Datagrid>
  </List>
);




export default CodescriptiontypeList;
