import React from 'react';
import {  
  Edit, FormTab, NumberInput, TextInput, DateInput, ReferenceInput, AutocompleteInput, SelectInput, EditButton,
  TabbedForm, ReferenceManyField, DeleteButton, Datagrid, NumberField, ReferenceField, TextField, SelectField, DateField, Button
} from 'react-admin';
import CustomNumberFieldRedirectToDoc from '../../CustomLayout/Components/CustomNumberFieldRedirectToDoc';

import Toolbar from '../../CustomLayout/Components/Toolbar';
import { statusOptions, auditTypes, flagFilter } from '../../const/const';
import {validatePositiveNumber} from '../../../utils/validation'

const AddTagButton = ({ record }) => (
  <Button
  label="Add a tag"
  href={`#/learningbadgetags/create?lbtlbid=${record.id}`}
  />
);

const AddDocButton = ({ record }) => (
  <Button
  label="Add a document"
  href={`#/learningbadgedocuments/create?lbdlbid=${record.id}`}
  />
);

const AddRangeButton = ({ record }) => (
  <Button
  label="Add a range"
  href={`#/learningbadgeranges/create?lbrlbid=${record.id}`}
  />
);

const LearningBadgeEdit = props => (
  <Edit {...props}>
    <TabbedForm toolbar={<Toolbar />}>
      <FormTab label="Badge">
        <NumberInput source="id" label="id" disabled/>
        <TextInput source="lbtitle" label="Titre" />
        <span>v Click here to see related docs</span>
        <CustomNumberFieldRedirectToDoc source="lbdoid" label="Document id" />
        <NumberInput source="lbdoid" label="id" validate={validatePositiveNumber}/>
        <NumberInput source="lbtype" label="Type" validate={validatePositiveNumber}/>
        <SelectInput source="lbstatusid" choices={statusOptions} optionValue="_id" label="Status"/>
        <SelectInput source="lbflag" choices={flagFilter} optionValue="_id" label='flag'/>
        <TextInput source="lbpic" label="Pic" type="url"/>
        <NumberInput source="lborder" label="lborder" validate={validatePositiveNumber}/>
        <ReferenceInput source="lbparentid" reference="learningbadges" label="Parent" filterToQuery={searchText => ({ lbtitle: searchText })}>
          <AutocompleteInput optionText="lbtitle"/>
        </ReferenceInput>
        <NumberInput source="lbparentid" label="ParentId" validate={validatePositiveNumber}/>
        <DateInput source="lbcreationdate" label="Creation Date" disabled/>
        <DateInput source="lbupdatedate" label="Update Date" disabled/>
      </FormTab>
      <FormTab label="Documents">
        <ReferenceManyField label="Documents" reference="learningbadgedocuments" target="lbdlbid">
          <Datagrid>
            <NumberField source="id" label="id" />
            <CustomNumberFieldRedirectToDoc source="lbddoid" label="Docid" />
            <SelectField source="lbdaudittype" choices={auditTypes} optionValue="_id" label="Audit Type"/>
            <NumberField source="lbdaudittype" label="Audit Id" />
            <NumberField source="lbdscore" label="Score" />
            <NumberField source="lbdflag" label="Flag" />
            <EditButton />
            <DeleteButton />
          </Datagrid>
        </ReferenceManyField>
        <AddDocButton />
      </FormTab>
      <FormTab label="Tags">
        <ReferenceManyField label="Tags" reference="learningbadgetags" target="lbtlbid" sort={{ field: 'lbttagid', order: 'asc' }}>
          <Datagrid rowClick="edit">
            <ReferenceField source="lbttagid" reference="tags" label="Tag">
              <TextField source="taname" label="name" />
            </ReferenceField>
            <NumberField source="lbtusid" label="User ID" />
            <NumberField source="lbtflag" label="Flag" />
            <DateField showTime source="lbtcreationdate" label="creationdate" />
            <DateField showTime source="lbtupdatedate" label="updatedate" />
            <DeleteButton />
          </Datagrid>
        </ReferenceManyField>
        <AddTagButton />
      </FormTab>
      <FormTab label="Range">
        <ReferenceManyField label="Tags" reference="learningbadgeranges" target="lbrlbid">
          <Datagrid rowClick="edit">
            <NumberField source="id" label="id" />
            <NumberField source="lbrscore" label="Score" />
            <NumberField source="lbrflag" label="Flag" />
            <DeleteButton />
          </Datagrid>
        </ReferenceManyField>
        <AddRangeButton />
      </FormTab>
    </TabbedForm>
  </Edit>
);

export default LearningBadgeEdit;
