import React from 'react';
import { Datagrid, TextField, Filter, NumberField, SelectInput, Pagination, ReferenceField, NumberInput, ReferenceInput, AutocompleteInput, List } from 'react-admin';
import { paginationRow, flagFilter } from '../../const/const';

const LearningBadgeRangeFilters = (props) => (
  <Filter {...props}>
      <NumberInput source="id" label="Search by id"/>
      <SelectInput source="lbrflag" choices={flagFilter} optionValue="_id" label="Search by flag"/>
      <NumberInput source="lbrlbid" label="Search by badgeid"/>
      <ReferenceInput source="lbrlbid" reference="learningbadges" label="Search by Badge" filterToQuery={searchText => ({ lbtitle: searchText })}>
        <AutocompleteInput optionText="lbtitle"/>
      </ReferenceInput>
  </Filter>
);

const NewPagination = props => <Pagination rowsPerPageOptions={paginationRow} {...props} />;

const LearningBadgeRangesList = props => (
  <List {...props}  bulkActionButtons={false} pagination={<NewPagination />} filters={<LearningBadgeRangeFilters/>}>
      <Datagrid rowClick="edit">
          <NumberField source="id" label="id" />
          <ReferenceField source="lbrlbid" reference="learningbadges" label="Badge">
            <TextField source="lbtitle" label="name" />
          </ReferenceField>
          <NumberField source="lbrlbid" label="Badge Id" />
          <NumberField source="lbrscore" label="Score" />
          <NumberField source="lbrflag" label="Flag" />
      </Datagrid>
  </List>
);

export default LearningBadgeRangesList;
