import React from 'react';
import { Edit, SimpleForm, TextInput, SelectInput, NumberInput, DateInput } from 'react-admin';
import Toolbar from '../../CustomLayout/Components/Toolbar';
import {validatePositiveNumber} from '../../../utils/validation'

import { descriptionType, flagFilter } from '../../const/const';

const DescriptionEdit = props => {
  // const notify = useNotify();
  // const refresh = useRefresh();
  // const redirect = useRedirect();
  // const Success = ({data}) => {
  //   notify('ra.notification.updated', 'info', { smart_count: 1 });
  //   redirect('edit', 'http://localhost:3006', data.id, data);
  //   refresh();
  // }
  return (
    <Edit {...props}>
        <SimpleForm toolbar={<Toolbar />}>
            <NumberInput source="id" label="id" disabled/>
            <NumberInput source="dedescriptionid" label="descriptionid" validate={validatePositiveNumber}/>
            <TextInput source="delink" label="delink"/>
            <SelectInput source="detypeid" choices={descriptionType} optionValue="_id" label="Type"/>
            <NumberInput source="devalue" label="value"/>
            <NumberInput source="deusid" label="deusid"  validate={validatePositiveNumber}/>
            <SelectInput source="deflag" choices={flagFilter} optionValue="_id" label='flag'/>
            <DateInput source="decreationdate" label="creationDate" disabled/>
            <DateInput source="deupdateDate" label="updatedate" disabled/>
        </SimpleForm>
    </Edit>
)};

export default DescriptionEdit;
