import React from 'react';
import { BooleanInput, ReferenceArrayField, TabbedForm, Edit, FormTab, ReferenceField, SelectInput,
  NumberInput, DateInput, TextField, SelectField, DateField, Datagrid, NumberField, Button, TextInput
} from 'react-admin';
import { descriptionType, typeOptions, langueOption, tagType, statusOptions, flagFilter } from '../../const/const';
import {validatePositiveNumber} from '../../../utils/validation'

import Toolbar from '../../CustomLayout/Components/Toolbar';

const AddTagButton = ({ record }) => (
  <Button
  label="Add a tag"
  href={`#/usertags/create?utuserid=${record.id}`}
  />
);
const AddDescriptionButton = ({ record }) => {
  return (
  <Button
  label="Add a description"
  href={`#/descriptions/create?dedescriptionid=${record.usdescriptionid}`}
  />
)};

const UserEdit = props => (
  <Edit {...props}>
    <TabbedForm toolbar={<Toolbar />}>
      <FormTab label="User">
          <NumberInput source="id" label="userId" disabled/>
          <ReferenceField label="Email" source="descriptionMailid" reference="descriptions">
            <TextField source="delink"/>
          </ReferenceField>
          <TextInput source="uspuid" label="puid"/>
          <SelectInput source="ustype" choices={typeOptions} optionValue="_id" />
          <NumberInput source="usdescriptionid" label="descriptionId" validate={validatePositiveNumber}/>
          <SelectInput source="usstatusid" choices={statusOptions} optionValue="_id" />
          <BooleanInput source="usanonymous" label="Anonynous"/>
          <BooleanInput source="usruledisplay" label="ruleDisplay"/>
          <TextInput source="usupdatesource" label="updateSource"/>
          <SelectInput source="uslanguage" choices={langueOption} optionValue="_id" label="Language"/>
          <SelectInput source="usflag" choices={flagFilter} optionValue="_id" label='flag'/>
          <DateInput source="uscreationdate" label="creationDate" disabled/>
          <DateInput source="usupdatedate" label="usupdatedate" disabled/>
          <NumberInput source="ustotallogins" label="totallogins"/>
          <NumberInput source="usloginerrors" label="loginerrors"/>
      </FormTab>
      <FormTab label="Description">
        <ReferenceArrayField label="Description" reference="descriptions" source="descriptionids">
          <Datagrid rowClick="edit">
              <NumberField source="id" label="id"/>
              <TextField source="delink" label="delink"/>
              <SelectField source="detypeid" choices={descriptionType} optionValue="_id" label="Type"/>
              <NumberField source="deflag" label="flag"/>
              <NumberField source="devalue" label="value"/>
              <TextField source="deusid" label="deusid"/>
              <DateField showTime source="decreationdate" label="creationDate" />
              <DateField showTime source="deupdateDate" label="updatedate" />
          </Datagrid>
        </ReferenceArrayField>
        <AddDescriptionButton />
      </FormTab>
      <FormTab label="Tags">
        <ReferenceArrayField label="Tags" reference="usertags" source="utids">
          <Datagrid rowClick="edit">
            <NumberField source="utuserid" label="utuserid" />
            <ReferenceField source="utid" reference="tags" label="Tag">
              <TextField source="taname" label="name" />
            </ReferenceField>
            <SelectField source="uttype" choices={tagType} optionValue="_id" />
            <NumberField source="utflag" label="flag"/>
            <DateField showTime source="utcreationdate" label="creationDate" />
            <DateField showTime source="utcreationdate" label="updateDate" />
            <TextField source='utupdatesource' label='utupdatesource' />
          </Datagrid>
        </ReferenceArrayField>
        <AddTagButton />
      </FormTab>
    </TabbedForm>
  </Edit>
);

export default UserEdit;