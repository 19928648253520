import React, { useState, useRef } from 'react';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { useQuery } from '@apollo/client';

import { marketQuery, brandQuery } from './../../../utils/graph/tags';

const Filters = ({ setMarketId, setBrandId }) => {
    const marketsData = useQuery(marketQuery);
    const brandsData = useQuery(brandQuery);

    const [marketMenuOpen, setMarketMenuOpen] = useState(false);
    const [brandMenuOpen, setBrandMenuOpen] = useState(false);
    const [marketName, setMarketName] = useState("");
    const [brandName, setBrandName] = useState("");

    const marketElRef = useRef(null);
    const brandElRef = useRef(null);

    const handleMarketClick = () => {
        setMarketMenuOpen(!marketMenuOpen);
      }
    
    const handleBrandClick = () => {
        setBrandMenuOpen(!marketMenuOpen);
    }

    const handleMarketItemClick = (tagId, tagTitle) => {
        setMarketMenuOpen(false);
        setMarketId(tagId);
        setMarketName(tagTitle);
    }

    const handleBrandItemClick = (tagId, tagTitle) => {
        setBrandMenuOpen(false);
        setBrandId(tagId);
        setBrandName(tagTitle);
    }

    const handleMarketClickAway = () => {
        setMarketMenuOpen(false);
    }

    const handleBrandClickAway = () => {
        setBrandMenuOpen(false);
    }

    return (
        <CardContent className="filters">
            <ClickAwayListener onClickAway={handleMarketClickAway}>
                <Button
                    classes={{label: "Mui_button"}}
                    onClick={handleMarketClick}
                >
                    Market Filter
                </Button>
            </ClickAwayListener>
            <h3
                className="filters_text"
                ref={marketElRef}
            >
                {marketName}
            </h3>
            <Menu
                anchorEl={marketElRef.current}
                open={marketMenuOpen}
            >
                <MenuItem onClick={() => handleMarketItemClick(null)}>None</MenuItem>
                {marketsData.data &&
                marketsData.data.tags.map((tag) => {
                    return (
                    <MenuItem
                        key={tag.title + tag.tagId}
                        onClick={() => handleMarketItemClick(tag.tagId, tag.title)}
                    >
                        {tag.title}
                    </MenuItem>
                    )
                })}
            </Menu>
            <ClickAwayListener onClickAway={handleBrandClickAway}>
                <Button
                    classes={{label: "Mui_button"}}
                    onClick={handleBrandClick}
                >
                    Brand Filter
                </Button>
            </ClickAwayListener>
            <h3
                className="filters_text"
                ref={brandElRef}
            >
                {brandName}
            </h3>
            <Menu
                anchorEl={brandElRef.current}
                open={brandMenuOpen}
            >
                <MenuItem onClick={() => handleBrandItemClick(null)}>None</MenuItem>
                {brandsData.data &&
                brandsData.data.tags.map((tag) => {
                    return (
                    <MenuItem
                        key={tag.title + tag.tagId}
                        onClick={() => handleBrandItemClick(tag.tagId, tag.title)}
                    >
                        {tag.title}
                    </MenuItem>
                    )
                })}
            </Menu>
        </CardContent>
    )
};

export default Filters;