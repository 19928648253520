import CoreObjectList from './views/CoreObjectList'
import CoreObjectEdit from './views/CoreObjectEdit'
import CoreObjectCreate from './views/CoreObjectCreate'

export default {
  name:"coreobjects",
  list:CoreObjectList,
  edit:CoreObjectEdit,
  create:CoreObjectCreate,
}
