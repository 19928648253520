import CoserverconfigList from './views/CoserverconfigList'
import CoserverconfigEdit from './views/CoserverconfigEdit'
import CoserverconfigCreate from './views/CoserverconfigCreate'

export default {
  name:"serverconfigs",
  list:CoserverconfigList,
  edit:CoserverconfigEdit,
  create:CoserverconfigCreate,
}
