import React from 'react';
import { SimpleForm, TextInput, Create, NumberInput, DateInput, ReferenceInput, AutocompleteInput, ImageInput, ImageField } from 'react-admin';
import {validatePositiveNumber} from '../../../utils/validation'

const UploadCreate = props => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="uptitle" label="uptitle" required/>
      <DateInput source="update" label="update" required/>
      <ReferenceInput source="uptaid" reference="tags" label="Tag" filterToQuery={searchText => ({ taname: searchText })}>
        <AutocompleteInput optionText="taname" required/>
      </ReferenceInput>
      <NumberInput source="updoid" label="Guidline" validate={validatePositiveNumber} required/>
      <ReferenceInput source="upcoid" reference="coreobjects" label="Boutique" filterToQuery={searchText => ({ cootitle: searchText, coonotionid: 6 })}>
        <AutocompleteInput optionText="cootitle" required/>
      </ReferenceInput>
      <ReferenceInput source="upusid" reference="users" label="Users">
        <ReferenceInput source="id" reference="descriptions" label="FullName" filterToQuery={searchText => ({ delink: searchText, detypeid: 1 })}>
          <AutocompleteInput optionText="delink" optionValue="deusid" required/>
        </ReferenceInput>
      </ReferenceInput>
      <NumberInput source="upusid" label="upusid" validate={validatePositiveNumber} required/>
      <ImageInput source="pics" label="VmPics" accept="image/*" multiple>
        <ImageField source="src" title="title" required/>
      </ImageInput>
    </SimpleForm>
  </Create>
);

export default UploadCreate;