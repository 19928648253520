import TagsMapManyToOneList from './views/tagsmapManyToOneList'
import TagsMapManyToOneEdit from './views/tagsmapManyToOneEdit'
import TagsMapManyToOneCreate from './views/tagsmapManyToOneCreate'

export default {
  name:"tagsmap-manytoone",
  list:TagsMapManyToOneList,
  edit:TagsMapManyToOneEdit,
  create:TagsMapManyToOneCreate
}


