import React from 'react';
import {  Edit, SimpleForm, NumberInput, ReferenceInput, AutocompleteInput, SelectInput } from 'react-admin';

import Toolbar from '../../CustomLayout/Components/Toolbar';
import { flagFilter } from '../../const/const';

const LearningBadgeRangeEdit = props => (
  <Edit {...props}>
    <SimpleForm toolbar={<Toolbar />}>
      <NumberInput source="id" label="id" disabled/>
      <ReferenceInput source="lbrlbid" reference="learningbadges" label="Badge" filterToQuery={searchText => ({ lbtitle: searchText })}>
        <AutocompleteInput optionText="lbtitle"/>
      </ReferenceInput>
      <NumberInput source="lbrscore" label="Score" />
      <SelectInput source="lbrflag" choices={flagFilter} optionValue="_id" label='flag'/>
    </SimpleForm>
  </Edit>
);

export default LearningBadgeRangeEdit;
