import React from 'react';
import { Edit, SimpleForm, TextInput, NumberInput, BooleanInput, ReferenceInput, AutocompleteInput, SelectInput } from 'react-admin';
import Toolbar from '../../CustomLayout/Components/Toolbar';
import {flagFilter} from '../../const/const'
import {validatePositiveNumber} from '../../../utils/validation'

const ClusterEdit = props => (
  <Edit {...props}>
      <SimpleForm toolbar={<Toolbar />}>
        <NumberInput source="id" label="clid" disabled/>
        <TextInput source="clname" label="name" />
        <BooleanInput source="cltype" label="type" />
        <ReferenceInput source="clmetatag" reference="tags" label="Metatag" filterToQuery={searchText => ({ taname: searchText })}>
          <AutocompleteInput optionText="taname"/>
        </ReferenceInput>
        <NumberInput source="clparentid" label="parentId" validate={validatePositiveNumber}/>
        <SelectInput source="clflag" choices={flagFilter} optionValue="_id" label="flag"/>
        <NumberInput source="cldeepness" label="deepness" validate={validatePositiveNumber}/>
      </SimpleForm>
  </Edit>
);

export default ClusterEdit;
