import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import IconButton from '@material-ui/core/IconButton';
import SyncIcon from '@material-ui/icons/Sync';

import Document from './Document';
import AddDocument from './AddDocument';

import './styles.css';

const DocumentsList = ({ badgeId, docs, links, tags, refetchBadges, selectDocs }) => {
    
    const handleRefreshClick = () => {
        if (refetchBadges) {
            refetchBadges();
        }
    }

    return (
    <Card>
        <CardContent className="documentsList">
            <h4 className="documentsList_cells_small" >Document ID</h4>
            <h4 className="documentsList_cells" >Document Title</h4>
            <h4 className="documentsList_cells" >Workflow</h4>
            <h4 className="documentsList_cells_small" >Score</h4>
            <h4 className="documentsList_cells" >Audit Type</h4>
            <div className="documentsList_cells">
                <IconButton
                    onClick={handleRefreshClick}
                >
                    <SyncIcon />
                </IconButton>
            </div>
        </CardContent>
        {links.map((link) => {
            let myDoc = {};
            if (docs) {
                docs.forEach((doc) => {
                    if (link.docId === doc.docId && link.badgeId === badgeId) {
                        myDoc = doc;
                    }
                })
            }
            return (
                <Document
                    key={Math.random()}
                    docId={link.docId}
                    title={myDoc.title || link.docId}
                    workflow={myDoc.workflow || ""}
                    badgeId={badgeId}
                    link={link}
                    refetchBadges={refetchBadges}
                />
            )}
        )}
        <AddDocument 
            badgeId={badgeId}
            tags={tags}
            refetchBadges={refetchBadges}
            selectDocs={selectDocs}
        />
    </Card>
)}

export default DocumentsList;