import React from 'react';
import { TextInput, Create, SimpleForm, ReferenceInput, SelectInput, NumberInput } from 'react-admin';

import { parse } from "query-string";
import {validatePositiveNumber, validateName} from '../../../utils/validation'
import {flagFilter} from '../../const/const'

const helperTextCouttname = "Must be camelCase";

const CodusertypeCreat = props => {
  const { coutnotionid: coutnotionid_string } = parse(props.location.search);
  const coutnotionid = coutnotionid_string ? parseInt(coutnotionid_string, 10) : "";

  const defaultValue = {
    coutnotionid,
    coutflag: 1,
    coutrole: 0,
  }
  return (
    <Create {...props}>
        <SimpleForm defaultValue={defaultValue} redirect={coutnotionid ? `/conotions/${coutnotionid}/3` : '/codusertypes'}>
          <TextInput helperText={helperTextCouttname} validate={validateName} source="couttname" label="Couttname" />
          <ReferenceInput label="notion" source="coutnotionid" reference="conotions">
            <SelectInput optionText="noname"  />
          </ReferenceInput>
          <NumberInput source="coutrole" label="coutrole" validate={validatePositiveNumber}/>
          <SelectInput source="coutflag" choices={flagFilter} optionValue="_id" label="flag"/>
        </SimpleForm>
    </Create>
  );
}
export default CodusertypeCreat;