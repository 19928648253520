import React from 'react';
import { SimpleForm, ArrayInput, SimpleFormIterator, TextInput, Create } from 'react-admin';
import { parse } from "query-string";

const BackofficeRoleCreate = props => {
  const { utuserid: utuserid_string } = parse(props.location.search);
  const utuserid = utuserid_string ? parseInt(utuserid_string, 10) : "";
  // const refresh = useRefresh();
  // const redirect = useRedirect();
  //
  // const tonSuccess = ({ data }) => {
  //   redirect(`/#/users/${utuserid}/2`);
  //   refresh();
  // };
  return (
  <Create {...props}>
      <SimpleForm defaultValue={{utuserid}}>
        <TextInput source="name" label="name" />
        <ArrayInput source="permissions">
          <SimpleFormIterator>
            <TextInput source="env" label="env"/>
          </SimpleFormIterator>
        </ArrayInput>
      </SimpleForm>
  </Create>
)};

export default BackofficeRoleCreate;
