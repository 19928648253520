import React from 'react';
import { SimpleForm, NumberInput, Create, ReferenceInput, AutocompleteInput, SelectInput} from 'react-admin';
import { flagFilter } from '../../const/const';

const TagClusterCreate = props => {
  return (
    <Create {...props} >
        <SimpleForm>
          <ReferenceInput source="clclusterid" reference="clusters" label="Cluster" filterToQuery={searchText => ({ clname: searchText })}>
            <AutocompleteInput optionText="clname" disabled/>
          </ReferenceInput>
          <ReferenceInput source="cltagid" reference="tags" label="Tag" filterToQuery={searchText => ({ taname: searchText })}>
            <AutocompleteInput optionText="taname"/>
          </ReferenceInput>
          <NumberInput source="clproba" label="Proba" />
          <SelectInput source="clflag" choices={flagFilter} optionValue="_id" label='flag'/>
      </SimpleForm>
    </Create>
  );
}

export default TagClusterCreate;
