import React from 'react';
import { Edit, SimpleForm, TextInput, ReferenceArrayInput, SelectArrayInput } from 'react-admin';
import Toolbar from '../../CustomLayout/Components/Toolbar';

const BackofficeUserEdit = props => (
  <Edit {...props}>
    <SimpleForm toolbar={<Toolbar />}>
        <TextInput source="id" label="id" disabled/>
        <TextInput source="name" label="name" disabled/>
        <ReferenceArrayInput label="Role" reference="roles" source="app_metadata.roles">
          <SelectArrayInput optionText="id" />
        </ReferenceArrayInput>
    </SimpleForm>
  </Edit>
);

export default BackofficeUserEdit;
