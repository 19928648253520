import React from 'react';
import {
  ReferenceArrayInput, Edit, SimpleForm, TextInput, TextField, AutocompleteArrayInput,
  ReferenceField,
} from 'react-admin';
import Toolbar from '../../CustomLayout/Components/Toolbar';

const TagsmapManyToOneEdit = props => (
  <Edit {...props}>
      <SimpleForm toolbar={<Toolbar />}>
        <TextInput source="id" label="tamtacopy" disabled/>
        <ReferenceField label="name" source="id" reference="tags">
            <TextField source="taname" />
          </ReferenceField>
        <ReferenceArrayInput label="tamatid" source="tamatid" reference="tags" filterToQuery={searchText => ({ "taname": searchText })}>
          <AutocompleteArrayInput optionText="taname"  />
        </ReferenceArrayInput>
      </SimpleForm>
  </Edit>
);

export default TagsmapManyToOneEdit;
