import React from 'react';
import {
  ReferenceArrayField, Datagrid, BooleanInput, Edit, TabbedForm, FormTab, TextInput,
  SelectInput, NumberInput, NumberField, TextField, Button,
} from 'react-admin';
import { statusOptions, tagSourceOptions, flagFilter } from '../../const/const';
import DeleteButtonConfirm from '../../CustomLayout/Components/DeleteButtonWithConfirmation';
import Toolbar from '../../CustomLayout/Components/Toolbar';
import {validatePositiveNumber} from '../../../utils/validation'

const AddTagLabelButton = ({ record }) => {
  return (
  <Button
  label="Add a tag Label"
  href={`#/taglabels/create?tltaid=${record.id}`}
  />
)};

const validateWeight = (value) => value < 0 ? "Weight must be positive or zero" : null;

const TagEdit = props => (
  <Edit {...props}>
      <TabbedForm toolbar={<Toolbar />}>
        <FormTab label='Tag'>
          <TextInput source="id" label="tagId" disabled/>
          <TextInput source="taname" label="name"/>
          <NumberInput source='taweight' label='weight' validate={validateWeight}/>
          <TextInput source="tadescription" label="description"/>
          <TextInput source="tapic" label="pic"/>
          <TextInput source="tasearchable" label="searchable"/>
          <SelectInput source="tastatusid" choices={statusOptions} optionText="name" label="status" optionValue="_id"/>
          <BooleanInput source='tafeature' label='feature'/>
          <SelectInput source="taflag" choices={flagFilter} optionValue="_id" label='flag'/>
          <NumberInput source='taruledisplay' label='ruleDisplay'/>
          <NumberInput source='taorder' label='order' validate={validatePositiveNumber}/>
          <BooleanInput source='taappfeature' label='appFeature'/>
          <BooleanInput source='taappmock' label='appMock'/>
          <NumberInput source='tarulemanage' label='ruleManage'/>
          <SelectInput source="tasource" choices={tagSourceOptions} optionText="name" label="source" optionValue="_id"/>
        </FormTab>
      <FormTab label='Labels'>
        <ReferenceArrayField label="Labels" reference="taglabels" source="tltaid">
            <Datagrid>
              <NumberField source="tltaid" label="tagId" />
              <NumberField source="tllanguage" label="language" />
              <TextField source="tllabel" label="label" />
              <NumberField source="tlflag" label="flag" />
              <DeleteButtonConfirm />
            </Datagrid>
          </ReferenceArrayField>
          <AddTagLabelButton />
      </FormTab>
    </TabbedForm>
  </Edit>
);

export default TagEdit;

//filterToQuery={searchText => ({ "tltaid": searchText })}
