import React from 'react';
import { SimpleForm, TextInput, SelectInput, Create, ReferenceInput } from 'react-admin';
import { parse } from "query-string";
import { flagFilter } from '../../const/const';
import {validateName} from '../../../utils/validation'

const helperTextCotname = "Must be camelCase";

const CotypeCreate = props => {
  const { cotnotionid: cotnotionid_string } = parse(props.location.search);
  const cotnotionid = cotnotionid_string ? parseInt(cotnotionid_string, 10) : "";
  const defaultValue = {
    cotnotionid,
    cotflag: 1,
  }
  return (
    <Create {...props} >
      <SimpleForm defaultValue={defaultValue} redirect={cotnotionid ? `/conotions/${cotnotionid}/1` : '/cotypes'}>
        <TextInput helperText={helperTextCotname} source="cotname" label="CO type name" validate={validateName} required/>
        <ReferenceInput label="notion" source="cotnotionid" reference="conotions">
          <SelectInput optionText="noname"  />
        </ReferenceInput>
        <SelectInput source="cotflag" choices={flagFilter} optionValue="_id" />
      </SimpleForm>
    </Create>
  );
}

export default CotypeCreate;
