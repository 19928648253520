import React from 'react';
import {  Edit, SimpleForm, NumberInput, TextInput, DateInput, SelectInput } from 'react-admin';
import Toolbar from '../../CustomLayout/Components/Toolbar';
import { documentTypes, languages, flagFilter } from '../../const/const';
import CustomNumberFieldRedirectToDoc from '../../CustomLayout/Components/CustomNumberFieldRedirectToDoc';
import {validatePositiveNumber} from '../../../utils/validation'

const DocumentEdit = props => (
  <Edit {...props}>
    <SimpleForm toolbar={<Toolbar />}>
      <NumberInput source="doid" label="doid"/>
      <span>v Click here to see related docs parentId</span>
      <CustomNumberFieldRedirectToDoc source="doparentid" label="ParentId"/>
      <NumberInput source="doparentid" label="Parentid" validate={validatePositiveNumber}/>
      <SelectInput source="dotype" choices={documentTypes} optionValue="_id" label="Type"/>
      <TextInput source="dotitle" label="Titre" />
      <TextInput source="dolink" label="Link" />
      <NumberInput source="dotemplate" label="Template" validate={validatePositiveNumber}/>
      <TextInput source="doupdatesource" label="Update Source" />
      <DateInput showTime source="doupdatedate" label="updatedate" disabled/>
      <SelectInput source="doflag" choices={flagFilter} optionValue="_id" label='flag'/>
      <NumberInput source="domdfid" label="domdfid" validate={validatePositiveNumber}/>
      <SelectInput source="dolanguage" choices={languages} optionValue="_id" label="Language"/>
      <span>v Click here to see related docs cloneId</span>
      <CustomNumberFieldRedirectToDoc source="doclonedfromid" label="CloneFromId" />
      <NumberInput source="doclonedfromid" label="CloneFromId" validate={validatePositiveNumber}/>
    </SimpleForm>
  </Edit>
);

export default DocumentEdit;
