import React from 'react';
import { 
  Datagrid, TextField, Filter, NumberField, SelectInput, Pagination, DateField, SelectField,
  ReferenceField, ImageField, TextInput, NumberInput, EditButton, List
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';

import { flagFilter, paginationRow, statusOptions } from '../../const/const';
import CustomNumberFieldRedirectToDoc from '../../CustomLayout/Components/CustomNumberFieldRedirectToDoc';

const LearningBadgeFilters = (props) => (
  <Filter {...props}>
      <NumberInput source="id" label="Search by id"/>
      <SelectInput source="lbflag" choices={flagFilter} optionValue="_id" label="Search by flag"/>
      <TextInput source="lbtitle" label="Search by title"/>
      <NumberInput source="lbdoid" label="Search by docid"/>
      <SelectInput source="lbstatusid" choices={statusOptions} optionValue="_id" label="Search by Status"/>
      <NumberInput source="lborder" label="Search by order"/>
      <NumberInput source="lbparentid" label="Search by parentId"/>
  </Filter>
);

const useStyles = makeStyles({
  imgContainer: {
    '& img': {
      height: 50,
      width: 50,
      objectFit: "contain"
    }
  }
});

const NewPagination = props => <Pagination rowsPerPageOptions={paginationRow} {...props} />;

const LearningBadgesList = props => {
  const classes = useStyles();
  return (
    <List {...props}  bulkActionButtons={false} pagination={<NewPagination />} filters={<LearningBadgeFilters />}>
        <Datagrid>
            <NumberField source="id" label="id" />
            <TextField source="lbtitle" label="Titre" />
            <CustomNumberFieldRedirectToDoc source="lbdoid" label="Docid" />
            <NumberField source="lbtype" label="Type" />
            <SelectField source="lbstatusid" choices={statusOptions} optionValue="_id" label="Status"/>
            <NumberField source="lbflag" label="Flag" />
            <ImageField source="lbpic" className={classes.imgContainer} label="Pic"/>
            <NumberField source="lborder" label="order" />
            <ReferenceField source="lbparentid" reference="learningbadges" label="Parent">
              <TextField source="id"/>
            </ReferenceField>
            <DateField showTime source="lbcreationdate" label="Creation Date" />
            <DateField showTime source="lbupdatedate" label="Update Date" />
            <EditButton/>
        </Datagrid>
    </List>
  );
}

export default LearningBadgesList;
