import React from 'react';
import { NumberInput, Edit, SimpleForm, TextInput, ReferenceInput, AutocompleteInput, SelectInput } from 'react-admin';

import Toolbar from '../../CustomLayout/Components/Toolbar';
import { tagType, flagFilter } from '../../const/const';
import {validatePositiveNumber} from '../../../utils/validation'

const UserTagEdit = props => (
  <Edit {...props}>
    <SimpleForm toolbar={<Toolbar />}>
        <NumberInput source="utuserid" label="utuserid" validate={validatePositiveNumber}/>
        <ReferenceInput source="utid" optionValue="_id" reference="tags" label="Tag" filterToQuery={searchText => ({ taname: searchText })}>
          <AutocompleteInput optionText="taname" />
        </ReferenceInput>
        <SelectInput source="uttype" choices={tagType} optionValue="_id" />
        <NumberInput source="utproba" label="proba" />
        <NumberInput source="utjoinedproba" label="joinedproba" />
        <NumberInput source="utxpdegree" label="xpdegree" />
        <TextInput source='utupdatesource' label='utupdatesource'/>
        <SelectInput source="utflag" choices={flagFilter} optionValue="_id" label='flag'/>
        <NumberInput source="utorder" label="order" />
        <NumberInput source="utalpha" label="alpha" />
    </SimpleForm>
  </Edit>
);

export default UserTagEdit;
