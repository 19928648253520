import TagClusterList from './views/TagClusterList'
import TagClusterEdit from './views/TagClusterEdit'
import TagClusterCreate from './views/TagClusterCreate'

export default {
  name:"tagclusters",
  list:TagClusterList,
  edit:TagClusterEdit,
  create:TagClusterCreate,
}
