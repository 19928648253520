import React, { useState } from 'react';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import { useMutation } from '@apollo/client';

import {
    removeLinkMutation,
    updateReadMutation,
    updateActivityMutation,
    } from '../../../utils/graph/documents'
import { AuditEnum } from '../../../utils/enum';

const Document = ({ docId, title, workflow, badgeId, link, refetchBadges }) => {

    const [confirmVisible, setConfirmVisible] = useState(false);
    const [audit] = useState(AuditEnum[link.auditTypeId]);

    const handleCloseClick = () => {
        setConfirmVisible(false);
    }

    const handleConfirmClick = () => {
        removeLink();
        if (refetchBadges) {
            refetchBadges();
        }
    }

    const handleReadClick = () => {
        updateRead();
        if (refetchBadges) {
            refetchBadges();
        }
    }

    const handleActivityClick = () => {
        updateActivity();
        if (refetchBadges) {
            refetchBadges();
        }
    }

    const handleRemoveClick = () => {
        setConfirmVisible(true);
    }

    const [removeLink]= useMutation(removeLinkMutation, {
        variables: {
            docId: Number(docId),
            badgeId: Number(badgeId),
        }
    });
    const [updateRead]= useMutation(updateReadMutation, {
        variables: {
            docId: docId,
            badgeId: badgeId,
        }
    });
    const [updateActivity]= useMutation(updateActivityMutation, {
        variables: {
            docId: docId,
            badgeId: badgeId,
        }
    });

    return (
        <CardContent className="documentsList">
            <h5 className="documentsList_cells_small" >{docId}</h5>
            <h5 className="documentsList_cells" >{title}</h5>
            <h5 className="documentsList_cells" >{workflow}</h5>
            <h5 className="documentsList_cells_small" >{link ? link.score : 1}</h5>
            <div className="documentsList_cells" >
                <Button
                    classes={{label: audit === "Read" ? "document_read" : "document_unselected"}}
                    disabled={audit === "Read"}
                    onClick={handleReadClick}
                >
                    Read
                </Button>
                <Button
                    classes={{label: audit === "Activity" ? "document_activity" : "document_unselected"}}
                    disabled={audit === "Activity"}
                    onClick={handleActivityClick}
                >
                    Activity
                </Button>
            </div>
            <div className="documentsList_cells">
                {!confirmVisible &&
                <Button
                    classes={{label: "document_removeButton"}}
                    onClick={handleRemoveClick}
                >
                    <div className="documentsList_cells_removeText">remove</div>
                </Button>
                }
                {confirmVisible &&
                <IconButton
                    onClick={handleConfirmClick}
                >
                    <CheckIcon />
                </IconButton>
                }
                {confirmVisible &&
                <IconButton
                    onClick={handleCloseClick}
                >
                    <CloseIcon />
                </IconButton>
                }
            </div>
        </CardContent>
    )
};

export default Document;