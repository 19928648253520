import React from 'react';
import { TextInput, Edit, SimpleForm, NumberInput, SelectInput, DateTimeInput, AutocompleteInput, ReferenceInput } from 'react-admin';
import Toolbar from '../../CustomLayout/Components/Toolbar';
import {validatePositiveNumber} from '../../../utils/validation'

import { flagFilter, statusOptions } from '../../const/const';


const CoreObjectEdit = props => {
  return (
  <Edit {...props}>
    <SimpleForm toolbar={<Toolbar />}>
      <ReferenceInput source="coonotionid" reference="conotions" label="coonotionid" filterToQuery={searchText => ({ noname: searchText })}>
        <AutocompleteInput optionText="noname" />
      </ReferenceInput>
      <TextInput source="cootitle" label="cootitle"/>
      <NumberInput source="cootypeid" label="cootypeid" validate={validatePositiveNumber}/>
      <SelectInput source="coostatusid" choices={statusOptions} optionValue="_id" label="coostatusid"/>
      <NumberInput source="coodoid" label="coodoid" validate={validatePositiveNumber}/>
      <NumberInput source="cootagid" label="cootagid" validate={validatePositiveNumber}/>
      <ReferenceInput source="cootagid" reference="tags" label="cootagid" filterToQuery={searchText => ({ taname: searchText })}>
        <AutocompleteInput optionText="taname" />
      </ReferenceInput>
      <SelectInput source="cooflag" choices={flagFilter} optionValue="_id" label="cooflag"/>
      <DateTimeInput showTime source="cooupdatedate" label="cooupdatedate"/>
      <DateTimeInput showTime source="coocreationdate" label="coocreationdate"/>
    </SimpleForm>
  </Edit>
)};

export default CoreObjectEdit;
