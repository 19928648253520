import { fetchUtils } from 'react-admin';
import { stringify } from 'query-string';
import axios from 'axios';

const apiUrl = process.env.REACT_APP_PROXY_SERVER_URL;
const env = localStorage.getItem('env-name')

const fetchJson = (url, options = {}) => {
  if (!options.headers) {
      options.headers = new Headers({ Accept: 'application/json' });
  }
  // add your own headers here
  options.headers.set('Authorization', `Bearer ${localStorage.getItem('token')}`);
  options.headers.set('env', localStorage.getItem('env-name'))
  return fetchUtils.fetchJson(url, options);
}

const dataProvider = {
    getList: (resource, { pagination, sort, filter }) => {
      return new Promise((resolve, reject) => {
        const { page, perPage } = pagination;
        const { field, order } = sort;
        axios({
          method: 'get',
          url: apiUrl + resource,
          headers: {
            env: localStorage.getItem('env-name'),
            Authorization: `Bearer ${localStorage.getItem('token')}`
          },
          params: {
            direction: order.toLowerCase(),
            sort: field,
            limit: perPage,
            skip: (page - 1) * perPage,
            filters: filter,
            filterValue: '',
          }
        }).then((response) => {
          const { status, headers, data } = response
          const total = parseInt(response.headers['content-range'].split('/').pop(), 10)
          return resolve({ status, headers, data, total });
        }).catch((e) => {
          if (!e.response)
            return reject({status: 500, message: e});
          const { status, data } = e.response
          return reject({status, message: data, data: e.response});
        });
      })
    },

    getOne: (resource, params) => {
      return new Promise((resolve, reject) => {
        axios({
          method: 'get',
          url: apiUrl + resource + '/' + params.id,
          headers: {
            env: localStorage.getItem('env-name'),
            Authorization: `Bearer ${localStorage.getItem('token')}`
          },
        }).then((response) => {
          const { status, headers, data } = response
          return resolve({ status, headers, data });
        }).catch((e) => {
          if (!e.response)
            return reject({status: 500, message: e});
          const { status, data } = e.response
          return reject({status, message: data, data: e.response});
        });
      });
    },

    getMany: (resource, params) => {
      return new Promise((resolve, reject) => {
        let Proms = params.ids.map(id => {
          return axios({
            method: 'get',
            url: `${apiUrl}${resource}/${id}`,
            headers: {
              env: env,
              Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
          });
        });
        Promise.all(Proms)
        .then((response) => {
          return resolve({ data: response.map(result => result.data) });
        }).catch((e) => {
          if (!e.response)
            return reject({status: 500, message: e});
          const { status, data } = e.response;
          return reject({status, message: data, data: e.response});
        });
      })
    },

    getManyReference: (resource, { pagination, sort, filter, target, id }) => {
      return new Promise((resolve, reject) => {
        const { page, perPage } = pagination;
        const { field, order } = sort;
        axios({
          method: 'get',
          url: apiUrl + resource,
          headers: {
            env: localStorage.getItem('env-name'),
            Authorization: `Bearer ${localStorage.getItem('token')}`
          },
          params: {
            direction: order.toLowerCase(),
            sort: field,
            limit: perPage,
            skip: (page - 1) * perPage,
            filters: {...filter, [target]: id},
            filterValue: '',
          }
        }).then((response) => {
          const { status, headers, data } = response
          const total = parseInt(response.headers['content-range'].split('/').pop(), 10)
          return resolve({ status, headers, data, total });
        }).catch((e) => {
          if (!e.response)
            return reject({status: 500, message: e});
          const { status, data } = e.response
          return reject({status, message: data, data: e.response});
        });
      })
    },
    update: (resource, params) => {
      return new Promise((resolve, reject) => {
        axios({
          method: 'put',
          url: apiUrl + resource + '/' + params.id,
          headers: {
            env: localStorage.getItem('env-name'),
            Authorization: `Bearer ${localStorage.getItem('token')}`
          },
          data: params.data
        })
        .then((response) => {
          const { status, headers, data } = response
          return resolve({ status, headers, data });
        }).catch((e) => {
          if (!e.response)
            return reject({status: 500, message: e});
          const { status, data } = e.response
          return reject({status, message: data, data: e.response});
        });
      })
    },

    updateMany: (resource, params) => {
        const query = {
            filter: JSON.stringify({ id: params.ids}),
        };
        return fetchJson(`${apiUrl}/${resource}?${stringify(query)}`, {
            method: 'PUT',
            body: JSON.stringify(params.data),
        }).then(({ json }) => ({ data: json }));
    },

    create: async (resource, params) => {
      return new Promise((resolve, reject) => {
        axios({
          method: 'post',
          url: apiUrl + resource,
          headers: {
            env: localStorage.getItem('env-name'),
            Authorization: `Bearer ${localStorage.getItem('token')}`
          },
          data: params.data
        })
        .then((response) => {
          const { status, headers, data } = response
          return resolve({ status, headers, data });
        }).catch((e) => {
          if (!e.response)
            return reject({status: 500, message: e});
          const { status, data } = e.response
          return reject({status, message: data, data: e.response});
        });
      })
    },

    delete: (resource, params) =>
        fetchJson(`${apiUrl}${resource}/${params.id}`, {
            method: 'DELETE',
        }).then(() => ({ data: { id: params.id } })),

    deleteMany: (resource, params) => {
        const query = {
            filter: JSON.stringify({ id: params.ids}),
        };
        return fetchJson(`${apiUrl}${resource}?${stringify(query)}`, {
            method: 'DELETE',
            body: JSON.stringify(params.data),
        }).then(({ json }) => ({ data: json }));
    }
};

const myDataProvider = {
  ...dataProvider,
  create: (resource, params) => {
      if (resource !== 'uploads' || !params.data.pics) {
          // fallback to the default implementation
          return dataProvider.create(resource, params);
      }
      // Freshly dropped pictures are File objects and must be converted to base64 strings
      const newPictures = params.data.pics.filter(
          p => p.rawFile instanceof File
      );
      const formerPictures = params.data.pics.filter(
          p => !(p.rawFile instanceof File)
      );

      return Promise.all(newPictures.map(convertFileToBase64))
          .then(base64Pictures =>
              base64Pictures.map(picture64 => ({
                  src: picture64,
                  title: `${params.data.title}`,
              }))
          )
          .then(transformedNewPictures =>
              dataProvider.create(resource, {
                  ...params,
                  data: {
                      ...params.data,
                      pics: [
                          ...transformedNewPictures,
                          ...formerPictures,
                      ],
                  },
              })
          );
  },
};

/**
* Convert a `File` object returned by the upload input into a base 64 string.
*/
const convertFileToBase64 = file =>
  new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result);
      reader.onerror = reject;

      reader.readAsDataURL(file.rawFile);
  });

export default myDataProvider;
