import React from 'react';
import { Datagrid, TextField, Filter, NumberField, SelectInput, Pagination, ReferenceField, DateField, NumberInput, ReferenceInput, AutocompleteInput, List } from 'react-admin';
import { paginationRow, flagFilter } from '../../const/const';

const LearningBadgeTagFilters = (props) => (
  <Filter {...props}>
      <SelectInput source="lbtflag" choices={flagFilter} optionValue="_id" label="Search by flag"/>
      <NumberInput source="lbtlbid" label="Search by badgeid"/>
      <ReferenceInput source="lbtlbid" reference="learningbadges" label="Search by Badge" filterToQuery={searchText => ({ lbtitle: searchText })}>
        <AutocompleteInput optionText="lbtitle"/>
      </ReferenceInput>
      <NumberInput source="lbttagid" label="Search by Tagid"/>
      <ReferenceInput source="lbttagid" reference="tags" label="Search by Tag" filterToQuery={searchText => ({ taname: searchText })}>
        <AutocompleteInput optionText="taname"/>
      </ReferenceInput>
  </Filter>
);

const NewPagination = props => <Pagination rowsPerPageOptions={paginationRow} {...props} />;

const LearningBadgeTagsList = props => (
  <List {...props} sort={{ field: 'lbttagid', order: 'asc' }}  bulkActionButtons={false} pagination={<NewPagination />} filters={<LearningBadgeTagFilters />}>
      <Datagrid rowClick="edit">
          <ReferenceField source="lbttagid" reference="tags" label="Tag">
            <TextField source="taname" label="name" />
          </ReferenceField>
          <ReferenceField source="lbtlbid" reference="learningbadges" label="Badge">
            <TextField source="lbtitle" label="title" />
          </ReferenceField>
          <NumberField source="lbtusid" label="User ID" />
          <NumberField source="lbtflag" label="Flag" />
          <DateField showTime source="lbtcreationdate" label="creationdate" />
          <DateField showTime source="lbtupdatedate" label="updatedate" />
      </Datagrid>
  </List>
);

export default LearningBadgeTagsList;
