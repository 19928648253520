import React from 'react';
import {
  Create, SimpleForm, AutocompleteInput, ReferenceArrayInput, AutocompleteArrayInput,
  ReferenceInput, required,
} from 'react-admin';

const TagsmapManyToOneCreate = props => (
  <Create {...props}>
      <SimpleForm>
        <ReferenceInput
          label="tag name"
          source="id"
          reference="tags"
          filterToQuery={searchText => ({ "taname": searchText })}
          validate={[required()]} >
          <AutocompleteInput optionText="taname" />
        </ ReferenceInput>
        <ReferenceArrayInput
          label="tamatid"
          source="tamatid"
          reference="tags"
          filterToQuery={searchText => ({ "taname": searchText })}>
          <AutocompleteArrayInput optionText="taname" />
        </ReferenceArrayInput>
      </SimpleForm>
  </Create>
);

export default TagsmapManyToOneCreate;
