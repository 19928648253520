import React from 'react';
import { Datagrid, TextField, ReferenceInput, AutocompleteInput, NumberInput, NumberField, ReferenceField, DateField, DeleteButton, Filter, SelectInput, Pagination, List } from 'react-admin';
import { flagFilter, paginationRow } from '../../const/const'

const TagGrantsFilter = (props) => (
  <Filter {...props}>
      <NumberInput source="tgtagid" label="Search by tagId"/>
      <ReferenceInput source="tgtagid" reference="tags" label="Search by Tag" filterToQuery={searchText => ({ taname: searchText })}>
        <AutocompleteInput optionText="taname"/>
      </ReferenceInput>
      <NumberInput source="tggranttagid" label="Search by tggranttagid"/>
      <ReferenceInput source="tggranttagid" reference="tags" label="Search by tggranttag" filterToQuery={searchText => ({ taname: searchText })}>
        <AutocompleteInput optionText="taname"/>
      </ReferenceInput>
      <SelectInput source="tgflag" choices={flagFilter} optionValue="_id" label="Search by flag"/>
  </Filter>
);

const NewPagination = props => <Pagination rowsPerPageOptions={paginationRow} {...props} />;

const TagGrantsList = props => (
  <List filters={<TagGrantsFilter/>} {...props}  bulkActionButtons={false} pagination={<NewPagination />}>
      <Datagrid >
          <NumberField source="tgtagid" label="tgtagid" />
          <ReferenceField source="tgtagid" reference="tags" label="tgtag" filterToQuery={searchText => ({ taname: searchText })}>
            <TextField source="taname" label="name" />
          </ReferenceField>
          <NumberField source="tggranttagid" label="tggranttagid" />
          <ReferenceField source="tggranttagid" reference="tags" label="taggrant tagid" filterToQuery={searchText => ({ taname: searchText })}>
            <TextField source="taname" label="name" />
          </ReferenceField>
          <NumberField source="tgflag" label="tgflag" />
          <DateField showTime source="tgcreationdate" label="creationDate" />
          <DateField showTime source="tgcreationdate" label="updateDate" />
          <DeleteButton/>
      </Datagrid>
  </List>
);




export default TagGrantsList;
