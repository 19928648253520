import React from 'react';
import { Datagrid, SelectField, TextField, NumberInput, Filter, TextInput, SelectInput, BooleanInput, BooleanField, DateField, NumberField, Pagination, List } from 'react-admin';
import { statusOptions, flagFilter, paginationRow } from '../../const/const'

const TagFilters = (props) => (
  <Filter {...props}>
      <NumberInput source="id" label="Search by tagid"/>
      <TextInput label="Search by name" source="taname"  />
      <NumberInput source="tastatusid" label="Search by statusId"/>
      <SelectInput source="taflag" choices={flagFilter} optionValue="_id" label="Search by flag"/>
      <BooleanInput source="tafeature" label='Search by feature'/>
      <NumberInput source="taorder" label="Search by taorder"/>
      <NumberInput source="taruledisplay" label="Search by taruledisplay"/>
      <NumberInput source="tarulemanage" label="Search by tarulemanage"/>
  </Filter>
);

const NewPagination = props => <Pagination rowsPerPageOptions={paginationRow} {...props} />;

const TagsList = props => (
  <List filters={<TagFilters />} {...props}  bulkActionButtons={false} pagination={<NewPagination />}>
      <Datagrid rowClick="edit">
          <NumberField source="id" label="tagId"/>
          <TextField component="pre" source="taname" label="name" />
          <NumberField source="tastatusid" label="statusId" />
          <SelectField source="tastatusid" choices={statusOptions} optionValue="_id" label="status" />
          <NumberField source="taflag" label="flag" />
          <BooleanField source='tafeature' label='feature' />
          <NumberField source='taruledisplay' label='ruleDisplay' />
          <NumberField source='taorder' label='order' />
          <BooleanField source='taappfeature' label='appFeature' />
          <BooleanField source='taappmock' label='appMock' />
          <NumberField source='tarulemanage' label='ruleManage' />
          <TextField source='tadescription' label='Description' />
          <DateField showTime source="tacreationdate" label="creationDate" />
          <DateField showTime source="taupdatedate" label="updateDate" />
      </Datagrid>
  </List>
);




export default TagsList;
