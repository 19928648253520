import React from 'react';
import { Datagrid, NumberField, TextField, Filter, TextInput, SelectInput, Pagination, List } from 'react-admin';
import { flagFilter, paginationRow } from '../../const/const'

const ConotionFilters = (props) => (
  <Filter {...props}>
      <TextInput label="Search by nocore" source="nocore"  />
      <TextInput label="Search by name" source="noname"  />
      <SelectInput choices={flagFilter} source="noflag" label="Search by flag" />
  </Filter> 
);

const NewPagination = props => <Pagination rowsPerPageOptions={paginationRow} {...props} />;

const ConotionList = props => (
  <List filters={<ConotionFilters />} {...props}  bulkActionButtons={false} pagination={<NewPagination />}>
      <Datagrid rowClick="edit">
        <TextField source="id" label="Id"/>
        <TextField source="nocore" label="Notion core" />
        <NumberField source="noflag" label="Flag" />
        <TextField source="noname" label="noname" />
      </Datagrid>
  </List>
);




export default ConotionList;