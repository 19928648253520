import React from 'react';
import { Datagrid, TextField, Filter, TextInput, NumberInput, NumberField, SelectField, SelectInput, Pagination, List } from 'react-admin';
import { langueOption, flagFilter, paginationRow } from '../../const/const';

const TagFilters = (props) => (
  <Filter {...props}>
      <NumberInput source="tltaid" label="Search by tagLabelid"/>
      <TextInput label="Search by label" source="tllabel" />
      <SelectInput source="tlflag" choices={flagFilter} optionValue="_id" label="Search by flag"/>
      <SelectInput source="tllanguage" choices={langueOption} optionValue="_id" label="Search by langue"/>
  </Filter>
);

const NewPagination = props => <Pagination rowsPerPageOptions={paginationRow} {...props} />;

const TagsList = props => (
  <List filters={<TagFilters />} {...props} sort={{ field: 'tltaid', order: 'asc' }}  bulkActionButtons={false} pagination={<NewPagination />}>
      <Datagrid rowClick="edit">
          <NumberField source="tltaid" label="tagLabelId"/>
          <SelectField source="tllanguage" choices={langueOption} optionValue="_id" label="language" />
          <TextField source="tllabel" label="label" />
          <NumberField source="tlflag" label="flag" />
      </Datagrid>
  </List>
);

export default TagsList;
