import React from 'react';
import { Datagrid, TextField, Pagination, List } from 'react-admin';
import { paginationRow } from '../../const/const'

const NewPagination = props => <Pagination rowsPerPageOptions={paginationRow} {...props} />;

const BackofficeRolesList = props => (
  <List {...props}  bulkActionButtons={false} pagination={<NewPagination />}>
      <Datagrid rowClick="edit">
          <TextField source="id" label="name" />
      </Datagrid>
  </List>
);




export default BackofficeRolesList;
