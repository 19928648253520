import axios from 'axios';

export default async (redisKey, notify) => {
  try {
    const response = await axios({
        method: 'POST',
        url: `${process.env.REACT_APP_PROXY_SERVER_URL}cache/clean`,
        headers: {
            env: localStorage.getItem('env-name'),
            Accept: 'application/json',
            Authorization: localStorage.getItem('token'),
        },
        data: {
            redisKey: redisKey,
        }
    })
    notify(`Cache: ${response.data.keysNumber} keys have been deleted`);
  } catch (e) {
    notify(`Error: Cache might be offline`);
  }
}