import React from 'react';
import { Datagrid, TextField, NumberInput, Filter, NumberField, SelectInput, Pagination, ReferenceField, DateField, ReferenceInput, AutocompleteInput, List } from 'react-admin';
import { flagFilter, paginationRow } from '../../const/const';

const TagFilters = (props) => (
  <Filter {...props}>
      <SelectInput source="clflag" choices={flagFilter} optionValue="_id" label="Search by flag"/>
      <NumberInput source="clclusterid" label="Search by clusterId"/>
      <ReferenceInput source="clclusterid" reference="clusters" label="Search by Cluster" filterToQuery={searchText => ({ clname: searchText })}>
        <AutocompleteInput optionText="clname"/>
      </ReferenceInput>
      <NumberInput source="cltagid" label="Search by Tagid"/>
      <ReferenceInput source="cltagid" reference="tags" label="Search by Tag" filterToQuery={searchText => ({ taname: searchText })}>
        <AutocompleteInput optionText="taname"/>
      </ReferenceInput>
      <NumberInput source="clproba" label="Search by Proba"/>
  </Filter>
);

const NewPagination = props => <Pagination rowsPerPageOptions={paginationRow} {...props} />;

const TagsList = props => (
  <List filters={<TagFilters />} sort={{ field: 'clclusterid', order: 'asc' }} {...props}  bulkActionButtons={false} pagination={<NewPagination />}>
      <Datagrid rowClick="edit">
          <ReferenceField source="clclusterid" reference="clusters" label="Cluster">
            <TextField source="clname" label="name" />
          </ReferenceField>
          <ReferenceField source="cltagid" reference="tags" label="Tag">
            <TextField source="taname" label="name" />
          </ReferenceField>
          <NumberField source="clproba" label="Proba" />
          <NumberField source="clflag" label="Flag" />
          <DateField showTime source="clcreationdate" label="creationDate" />
          <DateField showTime source="clupdatedate" label="updatedate" />
      </Datagrid>
  </List>
);

export default TagsList;
