import React from 'react';
import { NumberInput, Edit, SimpleForm, ReferenceInput, AutocompleteInput, SelectInput } from 'react-admin';
import Toolbar from '../../CustomLayout/Components/Toolbar';
import { flagFilter } from '../../const/const';

const TagGrantEdit = props => (
  <Edit {...props}>
    <SimpleForm toolbar={<Toolbar />}>
      <NumberInput source="tgtagid" label="tgtagid" />
      <ReferenceInput source="tggranttagid" reference="tags" label="tgtagid" filterToQuery={searchText => ({ taname: searchText })}>
        <AutocompleteInput optionText="taname" />
      </ReferenceInput>
      <SelectInput source="tgflag" choices={flagFilter} optionValue="_id" label='flag'/>
    </SimpleForm>
  </Edit>
);

export default TagGrantEdit;
