import React from 'react';
import {  Create, SimpleForm, NumberInput, ReferenceInput, AutocompleteInput, SelectInput } from 'react-admin';
import { parse } from "query-string";
import { flagFilter } from '../../const/const';
import {validatePositiveNumber} from '../../../utils/validation'

const LearningBadgeTagCreate = props => {
  const { lbtlbid: utuserid_string } = parse(props.location.search);
  const lbtlbid = utuserid_string ? parseInt(utuserid_string, 10) : "";
  const defaultValue = {
    lbttagid: 0,
    lbtlbid,
    lbtusid: 1,
    lbtflag: 1,
  }
  return (
    <Create {...props}>
      <SimpleForm defaultValue={defaultValue} redirect={lbtlbid ? `/learningbadges/${lbtlbid}/2` : '/learningbadgetags'}>
        <ReferenceInput source="lbttagid" reference="tags" label="Tag" filterToQuery={searchText => ({ taname: searchText })}>
          <AutocompleteInput optionText="taname"/>
        </ReferenceInput>
        <NumberInput source="lbttagid" label="User id" />
        <ReferenceInput source="lbtlbid" reference="learningbadges" label="Badge" filterToQuery={searchText => ({ lbtitle: searchText })}>
          <AutocompleteInput optionText="lbtitle"/>
        </ReferenceInput>
        <NumberInput source="lbtlbid" label="BadgeId" validate={validatePositiveNumber}/>
        <NumberInput source="lbtusid" label="User id" validate={validatePositiveNumber}/>
        <SelectInput source="lbtflag" choices={flagFilter} optionValue="_id" label='flag'/>
      </SimpleForm>
    </Create>
  );
}

export default LearningBadgeTagCreate;
