import React from 'react';
import { SimpleForm, TextInput, SelectInput, Create, NumberInput } from 'react-admin';
import { parse } from "query-string";
import {validatePositiveNumber} from '../../../utils/validation'

import { descriptionType, flagFilter } from '../../const/const';


const DescriptionCreate = props => {
  const { dedescriptionid: dedescriptionid_string } = parse(props.location.search);
  const dedescriptionid = dedescriptionid_string ? parseInt(dedescriptionid_string, 10) : "";

  return (
    <Create {...props}>
        <SimpleForm defaultValue={{dedescriptionid}} redirect={`/users/${dedescriptionid}/1`}>
            <NumberInput source="dedescriptionid" label="descriptionid" validate={validatePositiveNumber}/>
            <SelectInput source="detypeid" choices={descriptionType} optionValue="_id" label="Type"/>
            <TextInput source="delink" label="delink"/>
            <NumberInput source="devalue" label="value"/>
            <SelectInput source="deflag" choices={flagFilter} optionValue="_id" label='flag'/>
            <NumberInput initialValue={0} source="deusid" label="deusid" validate={validatePositiveNumber}/>
        </SimpleForm>
    </Create>
  );
}

export default DescriptionCreate;
