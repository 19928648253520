import { setContext } from '@apollo/client/link/context';
import {
    ApolloClient,
    createHttpLink,
    InMemoryCache
  } from '@apollo/client';
  
const httpLink = createHttpLink({
uri: `${process.env.REACT_APP_PROXY_SERVER_URL}gql`
});

const authLink = setContext((_, { headers }) => {
// get the authentication token from local storage if it exists
const token = localStorage.getItem('token');
// get the env variable
const env = localStorage.getItem('env-name');
// return the headers to the context so httpLink can read them
return {
    headers: {
    ...headers,
    authorization: token ? `Bearer ${token}` : "",
    env: env ? env : "",
    }
}
});

const client = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache({
        typePolicies: {
            Query: {
                fields: {
                    admin: {
                        merge(existing, incoming) {
                            return incoming;
                        }
                    }
                },
            },
            Mutation: {
                fields: {
                    admin: {
                        merge(existing, incoming) {
                            return incoming;
                        }
                    }
                },
            },
        },
    })
});

export default client;