import React from 'react';
import {List, Datagrid, TextField, NumberField, Filter, NumberInput, ReferenceField, Pagination, SelectInput, ReferenceInput, AutocompleteInput} from 'react-admin';
import { paginationRow, flagFilter } from '../../const/const';

const CoTagMapFilters = (props) => (
  <Filter {...props}>
    <NumberInput label="Search by tamtacopyId" source="tamtacopy"  />
    <ReferenceInput source="tamtacopy" reference="tags" label="Search by tamtacopy" filterToQuery={searchText => ({ taname: searchText })}>
      <AutocompleteInput optionText="taname"/>
    </ReferenceInput>
    <NumberInput label="Search by tamatid" source="tamatid"  />
    <ReferenceInput source="tamatid" reference="tags" label="Search by tamat" filterToQuery={searchText => ({ taname: searchText })}>
      <AutocompleteInput optionText="taname"/>
    </ReferenceInput>
    <SelectInput source="tamflag" choices={flagFilter} optionValue="_id" label="Search by flag"/>
  </Filter>
);

const NewPagination = props => <Pagination rowsPerPageOptions={paginationRow} {...props} />;

const CoTagMapsList = props => (
  <List filters={<CoTagMapFilters/>} {...props} sort={{field: 'tamatid', order: 'asc'}}  bulkActionButtons={false} pagination={<NewPagination />}>
    <Datagrid rowClick="edit">
      <NumberField source="tamatid" label="tamatid"/>
      <ReferenceField label="name" source="tamatid" reference="tags">
        <TextField source="taname"/>
      </ReferenceField>

      <NumberField source="tamtacopy" label="tamtacopy"/>
      <ReferenceField label="name" source="tamtacopy" reference="tags">
        <TextField source="taname"/>
      </ReferenceField>
      <NumberField source='tamflag' label='flag'/>
    </Datagrid>
  </List>
);

export default CoTagMapsList;
